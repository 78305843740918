import classnames from 'classnames';
import Accordion from 'components/common/Accordion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.css';

export interface IFaqProps {
  className?: string;
}
export const Faq: React.FC<IFaqProps> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={classnames('flex flex-col items-center', className)}>
      <h2 className={'max-w-[1170px] flex justify-center faq__title'}>
        {t('faq_title')}
      </h2>
      <div className={'flex flex-col max-w-[1170px] gap-[10px]'}>
        <Accordion title={t('faq.part1.title')}>
          {t('faq.part1.text')}
        </Accordion>
        <Accordion title={t('faq.part2.title')}>
          {t('faq.part2.text')}
        </Accordion>
        <Accordion title={t('faq.part3.title')}>
          {t('faq.part3.text')}
        </Accordion>
        <Accordion title={t('faq.part4.title')}>
          {t('faq.part4.text')}
        </Accordion>
        <Accordion title={t('faq.part5.title')}>
          <div>{t('faq.part5.text.part1')}</div>
          <ul>
            <li className="list-[square] ml-[20px]">
              {t('faq.part5.text.part2')}
            </li>
            <li className="list-[square] ml-[20px]">
              {t('faq.part5.text.part3')}
            </li>
            <li className="list-[square] ml-[20px]">
              {t('faq.part5.text.part4')}
            </li>
            <li className="list-[square] ml-[20px]">
              {t('faq.part5.text.part5')}
            </li>
            <li className="list-[square] ml-[20px]">
              {t('faq.part5.text.part6')}
            </li>
          </ul>
        </Accordion>
        <Accordion title={t('faq.part6.title')}>
          <div>{t('faq.part6.text.part1')}</div>
          <ul>
            <li className="list-[square] ml-[20px]">
              {t('faq.part6.text.part2')}
            </li>
            <li className="list-[square] ml-[20px]">
              {t('faq.part6.text.part3')}
            </li>
            <li className="list-[square] ml-[20px]">
              {t('faq.part6.text.part4')}
            </li>
            <li className="list-[square] ml-[20px]">
              {t('faq.part6.text.part5')}
            </li>
          </ul>
        </Accordion>
        <Accordion title={t('faq.part7.title')}>
          <div>{t('faq.part7.text.part1')}</div>
          <ol>
            <li className="list-decimal ml-[20px]">
              {t('faq.part7.text.part2')}
            </li>
            <li className="list-decimal ml-[20px]">
              {t('faq.part7.text.part3')}
            </li>
            <li className="list-decimal ml-[20px]">
              {t('faq.part7.text.part4')}
            </li>
            <li className="list-decimal ml-[20px]">
              {t('faq.part7.text.part5')}
            </li>
          </ol>
        </Accordion>
        <Accordion title={t('faq.part8.title')}>
          {t('faq.part8.text')}
        </Accordion>
        <Accordion title={t('faq.part9.title')}>
          {t('faq.part9.text')}
        </Accordion>
        <Accordion title={t('faq.part10.title')}>
          <div>{t('faq.part10.text.part1')}</div>
          <ul>
            <li className="list-[square] ml-[20px]">
              {t('faq.part10.text.part2')}
            </li>
            <li className="list-[square] ml-[20px]">
              {t('faq.part10.text.part3')}
            </li>
            <li className="list-[square] ml-[20px]">
              {t('faq.part10.text.part4')}
            </li>
            <li className="list-[square] ml-[20px]">
              {t('faq.part10.text.part5')}
            </li>
          </ul>
        </Accordion>
        <Accordion title={t('faq.part11.title')}>
          {t('faq.part11.text')}
        </Accordion>
        <Accordion title={t('faq.part12.title')}>
          {t('faq.part12.text')}
        </Accordion>
        <Accordion title={t('faq.part13.title')}>
          {t('faq.part13.text')}
        </Accordion>
        <Accordion title={t('faq.part14.title')}>
          {t('faq.part14.text')}
        </Accordion>
      </div>
    </div>
  );
};
