export const GTM_EVENTS = {
  USER_REGISTERED: 'user_registered',
  USER_AUTHORIZED: 'user_authorized',
  PAYMENT_STARTED: 'payment_started',
  PAYMENT_SUCCESS: 'payment_success',
  GENERATE_STARTED: 'generate_started',
  GENERATE_FINISHED: 'generate_finished',
  GO_PLAN: 'go_plan',
  PHOTO_UPLOAD: 'photo_upload',
  NEW_LANDING_VISITED: 'new_design_visited',

  // Onboarding
  ONBOARDING_START: 'ONBOARDING_START',
  ONBOARDING_SKIP: 'ONBOARDING_SKIP',
  ONBOARDING_STEP_1: 'ONBOARDING_STEP_1',
  ONBOARDING_STEP_2: 'ONBOARDING_STEP_2',
  ONBOARDING_STEP_3: 'ONBOARDING_STEP_3',
  ONBOARDING_STEP_4: 'ONBOARDING_STEP_4',
  ONBOARDING_STEP_5: 'ONBOARDING_STEP_5',
  ONBOARDING_COMPLETED: 'ONBOARDING_COMPLETED',
};

export const REGISTRATION_TYPES = {
  EMAIL: 'email',
  GOOGLE: 'google',
  DISCORD: 'discord',
  APPLE: 'apple',
};

export const USD = 'USD';

export const PAYMENT_TYPE = {
  CASH: 'cash',
  CRYPTO: 'crypto',
  CRYPTO_BY_CARD: 'crypto_by_card',
  CARD: 'card',
  PAYPAL: 'paypal',
};

export const AUTHORIZATION = 'authorization';

export const FEATURES_TYPES = {
  UPLOAD_CONTENT: 'UPLOAD_CONTENT',
  ADULT_CONTENT: 'ADULT_CONTENT',
};

export const CHILD_BLOCK = 'CHILD BLOCK';

export const AUTO = 'auto';

export const STYLE_OVERFLOW = {
  HIDDEN: 'hidden',
  VISIBLE: 'visible',
};
