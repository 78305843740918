import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SITE_NAME } from '../../../main';

export interface LogoProps {
  className?: string;
  fontSize?: number;
}

export const Logo: React.FC<LogoProps> = ({ className, fontSize = 28 }) => {
  const { t } = useTranslation();

  return (
    <div className={classnames('gap-[24px] flex', className)}>
      <div className="gap-[12px] self-stretch flex items-center">
        <img
          src="logo.svg"
          className="h-[31px] w-[45px]"
          alt="logo"
        />
        <div className={`text-[${fontSize}px]`}>{SITE_NAME}</div>
      </div>
    </div>
  );
};
