import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { useState, useRef, useEffect, useContext } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AccountRecoveryPopup from './authPopups/AccountRecoveryPopup';
import ConfirmEmail from './authPopups/ConfirmEmail';
import LoginPopup from './authPopups/LoginPopup';
import NewPasswordPopup from './authPopups/NewPasswordPopup';
import PasswordChangedPopup from './authPopups/PasswordChangedPopup';
import SignUpPopup from './authPopups/SignUpPopup';
import VerificationComplete from './authPopups/VerificationComplete';
import {
  AUTHORIZATION,
  GTM_EVENTS,
  REGISTRATION_TYPES,
  STYLE_OVERFLOW,
} from '../../constants/text';
import { GTMContext } from '../../context/GTMContext';
import {
  loginUser,
  confirmMail,
  checkCode,
  checkDeliveryEmail,
  getRecoveryCode,
  recoverPassword,
  metricChooseAuth,
  customAuthorizedMetric,
} from '../../packets/user/UserInfoRequestPacket';
import {
  sendUserInfoRequest,
} from '../../packets/user/UserInfoRequestPacket';
import { MAIN_EDITOR_URL } from '../../utils/text';

export function LoginButton({
  captchaToken,
  setCaptchaToken,
  checkAuthorizationButton,
  newVariant = false,
}) {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const [recovery, setRecovery] = useState(false);
  const [isUserRegistered, setIsUserRegistered] = useState('');
  const [emailVerificationError, setEmailVerificationError] = useState('');
  const [showRecoveryModal, setShowRecoveryModal] = useState(false);
  const [showNewPasswordPopup, setShowNewPasswordPopup] = useState(false);
  const [isLoginOpened, setIsLoginOpened] = useState(false);
  const [isSignUpOpened, setIsSignUpOpened] = useState(false);
  const [isEmailConfirmed, setIsEmailConfirmed] = useState(true);
  const [showApprovalCodeModal, setShowApprovalCodeModal] = useState(false);
  const [passwordSuccessfullyChanged, setPasswordSuccessfullyChanged] =
    useState(false);
  const [isPasswordWrong, setIsPasswordWrong] = useState(false);
  const [confirmCodeError, setConfirmCodeError] = useState('');
  const [codeInvalidMsg, setCodeInvalidMsg] = useState('');
  const [isVerificationComplete, setIsVerificationComplete] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState('');
  const [recoveryEmailError, setRecoveryEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const modalRef = useRef();
  const secondModalRef = useRef();
  const { dataLayerPush } = useContext(GTMContext);

  const checkAuthorizationButtonLite = async () => {
    if (Cookies.get('token') !== undefined) {
      await sendUserInfoRequest()
        .then(() => {
          navigate('/new-edit');
        })
        .catch((_e) => {
          console.log("Authorized__false__error" + _e);
        });
    } else {
      console.log('error__token__undefined');
    }
  };


  const handleSignUpClick = () => {
    setIsSignUpOpened(true);
    setIsLoginOpened(false);
  };

  const handleLoginClick = () => {
    metricChooseAuth('email');
    setIsLoginOpened(true);
    setIsSignUpOpened(false);
  };

  const handleClick = () => {
    setIsLoginOpened(!isLoginOpened);
  };

  const handleLogin = async (email, password, captcha) => {
    console.log('inside handleLogin', captcha);
    const response = await loginUser(
      email.toLowerCase(),
      password,
      captcha
    ).catch((e) => e.response);

    if (
      response.status === 400 &&
      response.data === 'CaptchaToken is invalid'
    ) {
      alert('Captcha is invalid :( Try again')
      setCaptchaToken();
      return;
    }

    if (response.status === 500) {
      dataLayerPush({
        event: GTM_EVENTS.USER_AUTHORIZED,
        event_cat: AUTHORIZATION,
        event_name: 'go',
        registration_type: REGISTRATION_TYPES.EMAIL,
        email,
        param_success: 'no',
      });
    }

    // CaptchaToken is invalid
    setEmailVerificationError('');
    setIsUserRegistered('false');

    if (
      response.data.success === 'true' &&
      response.data.reason === 'registered'
    ) {
      if (isSignUpOpened) {
        setRecovery(false);
        setShowApprovalCodeModal(true);
        setIsSignUpOpened(false);
        return;
      }

      if (isLoginOpened) {
        setIsUserRegistered('false');
        return;
      }
    }

    if (
      response.data.success === 'false' &&
      response.data.reason === 'need approve'
    ) {
      setRecovery(false);
      setShowApprovalCodeModal(true);
      setIsSignUpOpened(false);
      return;
    }
    console.log(response.data);
    if (
      response.data.success === 'false' &&
      response.data.reason === 'invalid password'
    ) {
      if (isLoginOpened) {
        setIsPasswordWrong(true);
        return;
      }

      if (isSignUpOpened) {
        setIsUserRegistered('true');
        return;
      } else {
        setIsUserRegistered('false');
      }
    }

    if (
      response.data.success === 'false' &&
      response?.data?.reason === 'verification email failed'
    ) {
      if (isSignUpOpened) {
        setEmailVerificationError(response?.data?.error);
        setCaptchaToken();
        return;
      }
    }

    if (response.data !== null) {
      Cookies.set('token', response.data);

      if (isSignUpOpened) {
        setIsUserRegistered('true');
        return;
      }
    }

    if (showApprovalCodeModal) {
      setIsVerificationComplete(true);
      setShowApprovalCodeModal(false);
    }

    if (isLoginOpened) {
      dataLayerPush({
        event: GTM_EVENTS.USER_AUTHORIZED,
        event_cat: AUTHORIZATION,
        event_name: 'go',
        registration_type: REGISTRATION_TYPES.EMAIL,
        email,
        param_success: 'yes',
      });
      checkAuthorizationButtonLite();
    }
  };

  const handleCheckDeliveryEmail = async (email) => {
    const response = await checkDeliveryEmail(email).catch((e) => e.response);

    if (!response.data.success) {
      if (response.data.deliveryFailed) {
        setConfirmCodeError(
          'Email with the verification code could not be sent'
        );
      }
    }
  };

  const handleConfirmCode = async (code) => {
    if (recovery) {
      const response = await checkCode(email, code).catch((e) => e.response);

      if (response.data.captchaFailed) {
        alert('4. Captcha expired! Please solve the Captcha again');
        navigate(0);
        return;
      }

      if (response.data.userNotFound) {
        setConfirmCodeError('User with this email is not found');
        return;
      }

      if (response.data.wrongAuthType) {
        setConfirmCodeError(
          'Current email is linked to another authorization method'
        );
        return;
      }

      if (response.data.userNotFound) {
        setConfirmCodeError('User with this email was not found');
      }

      if (response.data.resetNotActive) {
        setConfirmCodeError('Password reset was not requested');
      }

      if (response.data.wrongCode) {
        setConfirmCodeError('Reset code is incorrect');
      }

      if (response.data.success) {
        setShowNewPasswordPopup(true);
        setShowApprovalCodeModal(false);
      }
    } else {
      await confirmMail(email.toLowerCase(), code)
        .then(() => {
          dataLayerPush({
            event: GTM_EVENTS.USER_REGISTERED,
            user_id: email,
            reg_time: dayjs().format('YYYY-MM-DD'),
            req_source: 'direct',
            req_medium: '(none)',
            req_campaign: '(none)',
            req_keyword: '(none)',
            req_content: '/',
            event_name: 'go',
            registration_type: REGISTRATION_TYPES.EMAIL,
            param_success: 'yes',
            email,
          });
          handleLogin(email, password, captchaToken);
        })
        .catch((e) => {
          dataLayerPush({
            event: GTM_EVENTS.USER_REGISTERED,
            user_id: email,
            reg_time: dayjs().format('YYYY-MM-DD'),
            req_source: 'direct',
            req_medium: '(none)',
            req_campaign: '(none)',
            req_keyword: '(none)',
            req_content: '/',
            event_name: 'go',
            registration_type: REGISTRATION_TYPES.EMAIL,
            param_success: 'no',
            email,
          });
          setCodeInvalidMsg(e.response.data);
        });
    }
  };

  const handleSendRecoveryEmail = async () => {
    const response = await getRecoveryCode(
      email.trim(),
      Cookies.get('captcha_token')
    ).catch((e) => e.response);

    if (response.data.captchaFailed) {
      alert('5. Captcha expired! Please solve the Captcha again');
      navigate(0);
      return;
    }

    if (response.data.userNotFound) {
      setRecoveryEmailError('User with this email is not found');
      return;
    }

    if (response.data.wrongAuthType) {
      setRecoveryEmailError(
        'Current email is linked to another authorization method'
      );
      return;
    }

    if (response.data.success) {
      setRecovery(true);
      setShowApprovalCodeModal(true);
      setShowRecoveryModal(false);
    }
  };

  const handleRecoverPassword = async () => {
    const response = await recoverPassword(
      email,
      code,
      password,
      captchaToken
    ).catch((e) => e.response);

    if (response.data.userNotFound) {
      setNewPasswordError('User with this email was not found');
    }

    if (response.data.resetNotActive) {
      setNewPasswordError('Password reset was not requested');
    }

    if (response.data.wrongCode) {
      setNewPasswordError('Reset code is incorrect');
    }

    if (response.data.success) {
      setPasswordSuccessfullyChanged(true);
      setShowNewPasswordPopup(false);
    }
  };

  useEffect(() => {
    const handleBackButton = () => {
      if (isLoginOpened) setIsLoginOpened(false);
      if (isSignUpOpened) setIsSignUpOpened(false);
    };

    if (isLoginOpened || isSignUpOpened) {
      document.body.style.overflow = STYLE_OVERFLOW.HIDDEN;
      window.addEventListener('popstate', handleBackButton);
    }
    return () => {
      document.body.style.overflow = STYLE_OVERFLOW.VISIBLE;
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [isLoginOpened, isSignUpOpened]);

  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      {(isLoginOpened ||
        isSignUpOpened ||
        showApprovalCodeModal ||
        showRecoveryModal ||
        showNewPasswordPopup ||
        passwordSuccessfullyChanged ||
        isVerificationComplete) && (
        <div
          className="duration-400 fixed left-0 top-0 z-50 flex h-screen w-screen items-start justify-center bg-[#000000] animate-in fade-in lg:items-center"
          ref={modalRef}
        >
          {(isLoginOpened || isSignUpOpened) && (
            <div
              className="fixed flex h-full w-full flex-col items-center justify-center overflow-hidden animate-in fade-in-5 duration-1000"
              ref={secondModalRef}
            >
              <div
                className={`auth-login flex h-full w-full flex-col items-center gap-[48px] bg-[#000000] px-[24px] py-[24px] lg:h-auto lg:w-6/12 lg:rounded-[32px] lg:px-0 xl:w-5/12 2xl:w-4/12`}
              >
                <h3 className="whitespace-nowrap text-center text-[32px]/[32px]">
                  {false ? 'Log In' : 'Log In or Sign Up'}
                </h3>

                {false ? (
                  ''
                ) : (
                  <div className="flex w-full justify-between rounded-[32px] border border-[2px] border-[#FFFFFF1A] bg-[#000000]">
                    <button
                      onClick={handleLoginClick}
                      disabled={isLoginOpened}
                      className={`h-[48px] w-full rounded-full bg-[transparent] text-[16px]/[24px] text-[#FFFFFF] hover:text-[#FFFFFF80] disabled:bg-[#FF7100] disabled:hover:text-white ${
                        isSignUpOpened ? 'login' : ''
                      }`}
                    >
                      Log In
                    </button>
                    <button
                      onClick={handleSignUpClick}
                      disabled={isSignUpOpened}
                      className={`h-[48px] w-full rounded-full bg-[transparent] text-[16px]/[24px] text-[#FFFFFF] hover:text-[#FFFFFF80]  disabled:bg-[#FF7100] disabled:hover:text-white ${
                        isLoginOpened ? 'signup' : ''
                      }`}
                    >
                      Sign Up
                    </button>
                  </div>
                )}

                {isLoginOpened && (
                  <LoginPopup
                    closeModal={() => setIsLoginOpened(false)}
                    handleLogin={handleLogin}
                    email={email}
                    setEmail={setEmail}
                    password={password}
                    setPassword={setPassword}
                    isPasswordWrong={isPasswordWrong}
                    isUserRegistered={isUserRegistered}
                    isEmailConfirmed={isEmailConfirmed}
                    setShowRecoveryModal={setShowRecoveryModal}
                  />
                )}

                {isSignUpOpened && (
                  <>
                    <SignUpPopup
                      setCaptchaToken={setCaptchaToken}
                      closeModal={() => setIsSignUpOpened(false)}
                      handleLogin={handleLogin}
                      email={email}
                      setEmail={setEmail}
                      password={password}
                      setPassword={setPassword}
                      isUserRegistered={isUserRegistered}
                      captchaToken={captchaToken}
                      emailVerificationError={emailVerificationError}
                      setEmailVerificationError={setEmailVerificationError}
                    ></SignUpPopup>
                    <button
                        className="h-[64px] min-h-[64px] flex text-center p2-desktop justify-center items-center w-full rounded-[32px] hover:bg-[#232323] active:text-white/25"
                        onClick={()=>setIsSignUpOpened(false)}
                    >
                      {t('back')}
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
          {showApprovalCodeModal && (
            <ConfirmEmail
              closeModal={() => setShowApprovalCodeModal(false)}
              setShowApprovalCodeModal={setShowApprovalCodeModal}
              setIsLoginOpened={setIsLoginOpened}
              handleConfirmCode={handleConfirmCode}
              handleCheckDeliveryEmail={handleCheckDeliveryEmail}
              codeInvalidMsg={codeInvalidMsg}
              recovery={recovery}
              code={code}
              setCode={setCode}
              email={email}
              confirmCodeError={confirmCodeError}
            />
          )}

          {showRecoveryModal && (
            <AccountRecoveryPopup
              closeModal={() => setShowRecoveryModal(false)}
              setShowRecoveryModal={setShowRecoveryModal}
              setIsLoginOpened={setIsLoginOpened}
              handleSendRecoveryEmail={handleSendRecoveryEmail}
              email={email}
              setEmail={setEmail}
              recoveryEmailError={recoveryEmailError}
            />
          )}

          {showNewPasswordPopup && (
            <NewPasswordPopup
              password={password}
              setPassword={setPassword}
              setShowNewPasswordPopup={setShowNewPasswordPopup}
              handleRecoverPassword={handleRecoverPassword}
              newPasswordError={newPasswordError}
            />
          )}

          {isVerificationComplete && <VerificationComplete />}

          {passwordSuccessfullyChanged && <PasswordChangedPopup />}
        </div>
      )}
      {newVariant ? (
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className="flex items-center justify-center p-[6px] rounded-[14px] h-[56px] w-full bg-[#272628] cursor-pointer hover:bg-[#2E2A4B]"
          onClick={handleClick}
        >
          <img
            className="h-[24px] w-[24px]"
            src="/icons/mailLogo.svg"
            alt="mail logo"
          />
        </div>
      ) : (
        <>
          <button
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className="mmd:hidden flex justify-center items-center h-[48px] w-full bg-[#272628] rounded-[24px] p-[6px] self-center"
            onClick={handleClick}
          >
            <img
              className="h-[24px] w-[24px]"
              src="/icons/mailLogo.svg"
              alt="mail logo"
            />
          </button>
          <button
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className="mmd:flex hidden h-[88px] w-full items-center justify-between gap-[16px] self-stretch rounded-[44px] bg-[#121212] pl-[16px] pr-[24px] text-start transition-all hover:bg-[#FFFFFF]"
            onClick={handleClick}
          >
            <div className="flex items-center justify-center gap-[16px]">
              <div
                className={`flex h-[48px] w-[48px] items-center justify-center rounded-full ${isHovered ? '' : 'bg-[#272628]'} `}
              >
                {isHovered ? (
                  <>
                    <img
                      className="h-[24px] w-[24px]"
                      src="/icons/mailLogoDark.svg"
                      alt="mail logo"
                    />
                  </>
                ) : (
                  <>
                    <img
                      className="h-[24px] w-[24px]"
                      src="/icons/mailLogo.svg"
                      alt="mail logo"
                    />
                  </>
                )}
              </div>
              <div className="hidden flex flex-col mmd:flex">
                <p
                  className={`text-[12px] ${
                    isHovered ? 'text-[#20202080]' : 'text-[#FFFFFF80]'
                  }`}
                >
                  {t('login_with')}
                </p>
                <p
                  className={`text-[16px] font-semibold ${
                    isHovered ? 'text-[#000000]' : ''
                  }`}
                >
                  Email
                </p>
              </div>
            </div>

            <img
              src="/icons/keyIcon.svg"
              alt="key icon"
              className="hidden mmd:block h-[24px] w-[24px]"
            />
          </button>
        </>
      )}
    </>
  );
}
