import Cookies from 'js-cookie';
import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  GTM_EVENTS,
  REGISTRATION_TYPES,
  AUTHORIZATION,
} from '../../constants/text';
import { GTMContext } from '../../context/GTMContext';
import { sendLoginRequestV2 } from '../../packets/auth/LoginRequest';
import { customAuthorizedMetric } from '../../packets/user/UserInfoRequestPacket';
import { MAIN_EDITOR_URL } from '../../utils/text';

export function DiscordOAuth2Callback() {
  const navigate = useNavigate();
  const { dataLayerPush } = useContext(GTMContext);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');
    const captchaToken = urlParams.get('state');

    if (!code) {
      console.error('No code found in OAuth2 callback');
      return;
    }

    const handleSuccess = async (token) => {
      const loginResponse = await sendLoginRequestV2(
        token,
        'DISCORD',
        captchaToken || 'null'
      );

      // TODO: show error if status != 200
      if (loginResponse.data !== null) {
        Cookies.set('token', loginResponse.data.token);
      }
      customAuthorizedMetric('authorized');
      dataLayerPush({
        event_cat: AUTHORIZATION,
        event: `${
          loginResponse.data.isRegistration
            ? GTM_EVENTS.USER_REGISTERED
            : GTM_EVENTS.USER_AUTHORIZED
        }`,
        event_name: 'go',
        registration_type: REGISTRATION_TYPES.DISCORD,
        param_success: 'yes',
      });
      navigate(localStorage.getItem(MAIN_EDITOR_URL) || "/edit");
    };

    handleSuccess(code);
  }, [location]);

  return <div></div>;
}
