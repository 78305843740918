import React from 'react';
import { useSelector } from 'react-redux';
import './index.css';

type TLoaderProps = {
  forceLoading?: boolean;
};

const Loader = ({ forceLoading }: TLoaderProps) => {
  // @ts-expect-error will add later types for store
  const { loading } = useSelector((state) => state.errorSlice);

  if (!forceLoading && !loading) return null;

  return (
    <div className="loader-slice">
      <div className="loader-spinner"></div>
      <div>Loading...</div>
    </div>
  );
};

export default Loader;
