import classnames from 'classnames';
import React from 'react';
import './index.css';

interface IButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  secondary?: boolean;
  disabled?: boolean;
  className?: string;
}

const Button: React.FC<IButtonProps> = ({
  onClick,
  children,
  secondary,
  disabled,
  className,
}) => {
  return (
    <button
      className={classnames(
        'button',
        {
          'button-secondary': secondary,
          'button-disabled': disabled,
          'button-secondary-disabled': secondary && disabled,
        },
        className
      )}
      onClick={!disabled ? onClick : undefined}
    >
      {children}
    </button>
  );
};

export default Button;
