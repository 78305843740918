import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import Button from '../common/Button';
import Modal from '../common/Modal';

interface IModalInformation {
  instructionModalFunction: ({
    e,
    isActive,
  }: {
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    isActive: boolean;
  }) => void;
  showInstructionModal: boolean;
}

function ModalInstruction({
  instructionModalFunction,
  showInstructionModal,
}: IModalInformation) {
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  function nextPage(
    e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
  ): void {
    e.stopPropagation();
    setPage((prev) => prev + 1);
  }
  function prevPage(
    e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
  ): void {
    e.stopPropagation();
    setPage((prev) => (prev > 1 ? prev - 1 : prev));
  }
  function firstPage(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    e.stopPropagation();
    setPage(1);
  }
  function lastPage(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    e.stopPropagation();
    setPage(3);
  }
  function stubClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    e.stopPropagation();
  }

  return ReactDOM.createPortal(
    <>
      <Modal
        closeOnOverlayClick={true}
        isOpen={showInstructionModal}
        onClose={(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          instructionModalFunction({ e, isActive: false })
        }
        classname="bg-[#171717] rounded-2xl"
        children={
          <div
            className="w-[100%] h-auto"
            onClick={(e) => stubClick(e)}
          >
            <div className="flex justify-around mt-10 gap-4 smd:gap-2 smd:mt-5">
              {page === 1 && (
                <>
                  <div
                    className={`bg-[url('/playground_assets/infoModalBanner1.jpeg')] smd:w-[50%] smd:h-auto bg-center bg-cover smd:bg-no-repeat smd:bg-contain w-[19.75rem] h-[25.75rem] rounded-2xl`}
                  ></div>
                  <div
                    className={`bg-[url('/playground_assets/infoModalBanner2.jpeg')] smd:w-[50%] smd:h-[14rem] bg-center bg-cover smd:bg-no-repeat smd:bg-contain w-[19.75rem] h-[25.75rem] rounded-2xl`}
                  ></div>
                </>
              )}
              {page === 2 && (
                <>
                  <div
                    className={`bg-[url('/playground_assets/infoModalBanner3.jpeg')] smd:w-[50%] smd:h-auto bg-center bg-cover smd:bg-no-repeat smd:bg-contain w-[19.75rem] h-[25.75rem] rounded-2xl`}
                  ></div>
                  <div
                    className={`bg-[url('/playground_assets/infoModalBanner4.jpeg')] smd:w-[50%] smd:h-[14rem] bg-center bg-cover smd:bg-no-repeat smd:bg-contain w-[19.75rem] h-[25.75rem] rounded-2xl`}
                  ></div>
                </>
              )}
              {page === 3 && (
                <>
                  <div
                    className={`bg-[url('/playground_assets/infoModalBanner5.jpeg')] smd:w-[50%] smd:h-auto bg-center bg-cover smd:bg-no-repeat smd:bg-contain w-[19.75rem] h-[25.75rem] rounded-2xl`}
                  ></div>
                  <div
                    className={`bg-[url('/playground_assets/infoModalBanner6.jpeg')] smd:w-[50%] smd:h-[14rem] bg-center bg-cover smd:bg-no-repeat smd:bg-contain w-[19.75rem] h-[25.75rem] rounded-2xl`}
                  ></div>
                </>
              )}
            </div>
            <div className="w-full flex justify-center items-center">
              <p className="my-4 smd:text-sm text-lg w-[90%] text-center text-[#C8C7CE]">
                {page === 1 && (
                  <>
                    {t('modal_instruction.part1')}
                    <br />
                    {t('modal_instruction.part2')}
                  </>
                )}
                {page === 2 && t('modal_instruction.part3')}
                {page === 3 && t('modal_instruction.part4')}
              </p>
            </div>
            <div className="flex smd:hidden justify-around items-center">
              {page === 1 ? (
                <>
                  <Button
                    disabled
                    onClick={(
                      e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => prevPage(e)}
                  >
                    {t('back')}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    secondary
                    onClick={(
                      e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => prevPage(e)}
                  >
                    {t('back')}
                  </Button>
                </>
              )}

              <div className="flex justify-between gap-2 ">
                {page === 1 && (
                  <>
                    <div className="bg-[#EB7201] w-[4rem] h-[1rem] rounded-xl cursor-default"></div>
                    <div
                      className="bg-[#434247] w-[1rem] h-[1rem] rounded-xl hover:border-[3px] hover:border-[#EB7201] cursor-pointer"
                      onClick={(e) => nextPage(e)}
                    ></div>
                    <div
                      className="bg-[#434247] w-[1rem] h-[1rem] rounded-xl hover:border-[3px] hover:border-[#EB7201] cursor-pointer"
                      onClick={(e) => lastPage(e)}
                    ></div>
                  </>
                )}
                {page === 2 && (
                  <>
                    <div
                      className="bg-[#434247] w-[1rem] h-[1rem] rounded-xl hover:border-[3px] hover:border-[#EB7201] cursor-pointer"
                      onClick={(e) => firstPage(e)}
                    ></div>
                    <div className="bg-[#EB7201] w-[4rem] h-[1rem] rounded-xl cursor-default"></div>
                    <div
                      className="bg-[#434247] w-[1rem] h-[1rem] rounded-xl hover:border-[3px] hover:border-[#EB7201] cursor-pointer"
                      onClick={(e) => lastPage(e)}
                    ></div>
                  </>
                )}
                {page === 3 && (
                  <>
                    <div
                      className="bg-[#434247] w-[1rem] h-[1rem] rounded-xl hover:border-[3px] hover:border-[#EB7201] cursor-pointer"
                      onClick={(e) => firstPage(e)}
                    ></div>
                    <div
                      className="bg-[#434247] w-[1rem] h-[1rem] rounded-xl hover:border-[3px] hover:border-[#EB7201] cursor-pointer"
                      onClick={(e) => prevPage(e)}
                    ></div>
                    <div className="bg-[#EB7201] w-[4rem] h-[1rem] rounded-xl cursor-default"></div>
                  </>
                )}
              </div>
              {page === 3 ? (
                <>
                  <Button
                    onClick={(
                      e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => instructionModalFunction({ e, isActive: false })}
                  >
                    Close
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={(
                      e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => nextPage(e)}
                  >
                    {t('next')}
                  </Button>
                </>
              )}
            </div>
            <div className="hidden smd:flex flex-col gap-2 justify-around items-center">
              <div className="flex justify-between gap-2 ">
                {page === 1 && (
                  <>
                    <div className="bg-[#EB7201] w-[4rem] h-[1rem] rounded-xl"></div>
                    <div
                      className="bg-[#434247] w-[1rem] h-[1rem] rounded-xl hover:border-[3px] hover:border-[#EB7201]"
                      onClick={(e) => nextPage(e)}
                    ></div>
                    <div
                      className="bg-[#434247] w-[1rem] h-[1rem] rounded-xl hover:border-[3px] hover:border-[#EB7201]"
                      onClick={(e) => lastPage(e)}
                    ></div>
                  </>
                )}
                {page === 2 && (
                  <>
                    <div
                      className="bg-[#434247] w-[1rem] h-[1rem] rounded-xl hover:border-[3px] hover:border-[#EB7201]"
                      onClick={(e) => firstPage(e)}
                    ></div>
                    <div className="bg-[#EB7201] w-[4rem] h-[1rem] rounded-xl"></div>
                    <div
                      className="bg-[#434247] w-[1rem] h-[1rem] rounded-xl hover:border-[3px] hover:border-[#EB7201]"
                      onClick={(e) => lastPage(e)}
                    ></div>
                  </>
                )}
                {page === 3 && (
                  <>
                    <div
                      className="bg-[#434247] w-[1rem] h-[1rem] rounded-xl hover:border-[3px] hover:border-[#EB7201]"
                      onClick={(e) => firstPage(e)}
                    ></div>
                    <div
                      className="bg-[#434247] w-[1rem] h-[1rem] rounded-xl hover:border-[3px] hover:border-[#EB7201]"
                      onClick={(e) => prevPage(e)}
                    ></div>
                    <div className="bg-[#EB7201] w-[4rem] h-[1rem] rounded-xl"></div>
                  </>
                )}
              </div>
              {page === 1 ? (
                <>
                  <Button
                    disabled
                    onClick={(
                      e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => prevPage(e)}
                  >
                    Back
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    secondary
                    onClick={(
                      e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => prevPage(e)}
                  >
                    Back
                  </Button>
                </>
              )}

              {page === 3 ? (
                <>
                  <Button
                    onClick={(
                      e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => instructionModalFunction({ e, isActive: false })}
                  >
                    Close
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={(
                      e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => nextPage(e)}
                  >
                    {t('next')}
                  </Button>
                </>
              )}
            </div>
          </div>
        }
      />
    </>,
    document.getElementById('root')
  );
}

export default ModalInstruction;
