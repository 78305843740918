import axios from 'axios';
import Cookies from 'js-cookie';
import { getGoogleClientId, getUtm } from 'utils/utils.js';

import { API_HOST, SITE_NAME } from '../../main';
import { getUserCode } from '../user/UserInfoRequestPacket';

export function loginRequest(
  token,
  fingerprint,
  authMethod,
  inviteCode,
  captchaToken,
  clientId,
  keitaroSubId,
  metricUserCode
) {
  const utm = getUtm();

  const base = {
    authMethod: authMethod,
    token: token,
    inviteCode: inviteCode,
    captchaToken: captchaToken,
    site: SITE_NAME,
    clientId: clientId,
    keitaroSubId: keitaroSubId,
    metricUserCode: metricUserCode,
  };

  return Object.assign(base, utm);
}

export async function sendLoginRequest(token, authMethod, captchaToken) {
  console.log('captcha token in request', captchaToken);
  const clientId = await getGoogleClientId();

  return axios.post(
    `${API_HOST}/api/v1/login2`,
    JSON.stringify(
      loginRequest(
        token,
        Cookies.get('fpHash'),
        authMethod,
        Cookies.get('referral'),
        Cookies.get('captchaToken'),
        clientId,
        Cookies.get('keitaroSubId'),
        getUserCode()
      )
    )
  );
}

export async function sendLoginRequestV2(token, authMethod, captchaToken) {
  console.log('capthca in 2nd request', captchaToken);
  const clientId = await getGoogleClientId();

  return axios.post(
    `${API_HOST}/api/v1/login2`,
    JSON.stringify(
      loginRequest(
        token,
        Cookies.get('fpHash'),
        authMethod,
        Cookies.get('referral'),
        captchaToken,
        clientId,
        Cookies.get('keitaroSubId'),
        getUserCode()
      )
    )
  );
}
