import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface queueSliceState {
  queuePosition: number | null;
  eta: number;
  initialEta: number | null;
  generationProgress: number;
}

const initialState: queueSliceState = {
  queuePosition: 0 || null,
  eta: 0,
  initialEta: 0 || null,
  generationProgress: -1,
};

export const queueSlice = createSlice({
  name: 'queue',
  initialState,
  reducers: {
    setQueuePosition: (state, action: PayloadAction<number | null>) => {
      state.queuePosition = action.payload;
    },
    setEta: (state, action: PayloadAction<number>) => {
      state.eta = action.payload;
    },
    setInitialEta: (state, action: PayloadAction<number | null>) => {
      state.initialEta = action.payload;
    },
    setProgress: (state, action: PayloadAction<number>) => {
      state.generationProgress = action.payload;
    },
  },
});

export const { setQueuePosition, setEta, setInitialEta, setProgress } =
  queueSlice.actions;

export default queueSlice.reducer;
