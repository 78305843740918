import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { STYLE_OVERFLOW } from '../../../constants/text';

export default function ConfirmEmail({
  setIsLoginOpened,
  setShowApprovalCodeModal,
  closeModal,
  handleConfirmCode,
  handleCheckDeliveryEmail,
  codeInvalidMsg,
  confirmCodeError,
  setCode,
  recovery,
  email,
  code,
}) {
  const [isCodeCorrect, setIsCodeCorrect] = useState(true);
  const { t } = useTranslation();

  const code1Ref = useRef();
  const code2Ref = useRef();
  const code3Ref = useRef();
  const code4Ref = useRef();
  const code5Ref = useRef();
  const code6Ref = useRef();
  const [code1, setCode1] = useState('');
  const [code2, setCode2] = useState('');
  const [code3, setCode3] = useState('');
  const [code4, setCode4] = useState('');
  const [code5, setCode5] = useState('');
  const [code6, setCode6] = useState('');

  useEffect(() => {
    const handleBackButton = () => {
      setIsLoginOpened(true);
      setShowApprovalCodeModal(false);
    };

    setInterval(() => handleCheckDeliveryEmail(email), 15000);

    document.body.style.overflow = STYLE_OVERFLOW.HIDDEN;
    window.addEventListener('popstate', handleBackButton);
    return () => {
      document.body.style.overflow = STYLE_OVERFLOW.VISIBLE;
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  const handleLoginClick = () => {
    const tempCode =
      code1.toString() +
      code2.toString() +
      code3.toString() +
      code4.toString() +
      code5.toString() +
      code6.toString();

    if (tempCode.length !== 6) {
      setIsCodeCorrect(false);
      return;
    } else {
      setIsCodeCorrect(true);
    }

    setCode(tempCode);

    handleConfirmCode(tempCode);
  };

  useEffect(() => {
    if (code6) {
      handleLoginClick();
    }
  }, [code6]);

  const handleInputChange = (e, setCode, code, codeRef) => {
    if (e.target.value.length === 6) {
      setCode1(e.target.value[0]);
      setCode2(e.target.value[1]);
      setCode3(e.target.value[2]);
      setCode4(e.target.value[3]);
      setCode5(e.target.value[4]);
      setCode6(e.target.value[5]);
    }

    if (e.target.value.length <= 1 && code.length <= 1) {
      setCode(e.target.value);
      codeRef.current.focus();
    }
  };

  return (
    <div className="fixed flex items-center h-full justify-center flex-col w-full animate-in fade-in-5 duration-1000">
      <form
        className="auth-login auth-confirm margin-top-0  h-screen flex flex-col gap-[16px] lg:gap-[28px] px-[24px] py-[24px] pt-[32px] w-full lg:w-6/12 xl:w-5/12 2xl:w-4/12 lg:rounded-[32px] items-center justify-between lg:justify-center bg-[#000000]"
        onSubmit={(event) => {
          event.preventDefault();
          handleLoginClick();
        }}
      >
        <div className="flex items-center w-full lg:h-auto flex-col">
          <h2 className="text-[32px]/[32px] mb-[32px] lg:mb-[48px]">
            {t('verification')}
          </h2>

          <p className="text-center text-[16px]/[24px] text-white/50 mb-[16px] md:whitespace-nowrap">
            {t('confirm_email')}
          </p>
          <div className="flex flex-col w-full gap-[16px] mb-[16px] relative">
            <div className="w-full flex gap-[4px] lg:gap-[8px] justify-center">
              <input
                type="number"
                ref={code1Ref}
                className={`input-mobile text-[24px]/[24px] h-[48px] flex items-center justify-center text-center placeholder-[#FFFFFF40] bg-[#121212] rounded-[12px]
                  ${
                    isCodeCorrect &&
                    codeInvalidMsg === '' &&
                    confirmCodeError === ''
                      ? 'focus:outline-white/50 focus:outline-[0px] '
                      : 'border-[#FF3535] focus:outline-[#FF3535] focus:outline-[0px]  border-[1px]'
                  }`}
                placeholder="_"
                maxLength={1}
                value={code1}
                onChange={(e) => {
                  handleInputChange(e, setCode1, code1, code2Ref);
                }}
              />

              <input
                type="number"
                ref={code2Ref}
                className={`input-mobile text-[24px]/[24px] h-[48px] flex items-center justify-center text-center placeholder-[#FFFFFF40] bg-[#121212] rounded-[12px]
                  ${
                    isCodeCorrect &&
                    codeInvalidMsg === '' &&
                    confirmCodeError === ''
                      ? 'focus:outline-white/50 focus:outline-[0px] '
                      : 'border-[#FF3535] focus:outline-[#FF3535] focus:outline-[0px]  border-[1px]'
                  }`}
                placeholder="_"
                maxLength={1}
                value={code2}
                onChange={(e) => {
                  handleInputChange(e, setCode2, code2, code3Ref);
                }}
              />

              <input
                type="number"
                ref={code3Ref}
                className={`input-mobile text-[24px]/[24px] h-[48px] flex items-center justify-center text-center placeholder-[#FFFFFF40] bg-[#121212] rounded-[12px]
                  ${
                    isCodeCorrect &&
                    codeInvalidMsg === '' &&
                    confirmCodeError === ''
                      ? 'focus:outline-white/50 focus:outline-[0px] '
                      : 'border-[#FF3535] focus:outline-[#FF3535] focus:outline-[0px]  border-[1px]'
                  }`}
                placeholder="_"
                maxLength={1}
                value={code3}
                onChange={(e) => {
                  handleInputChange(e, setCode3, code3, code4Ref);
                }}
              />

              <input
                type="number"
                ref={code4Ref}
                className={`input-mobile text-[24px]/[24px] h-[48px] flex items-center justify-center text-center placeholder-[#FFFFFF40] bg-[#121212] rounded-[12px]
                  ${
                    isCodeCorrect &&
                    codeInvalidMsg === '' &&
                    confirmCodeError === ''
                      ? 'focus:outline-white/50 focus:outline-[0px] '
                      : 'border-[#FF3535] focus:outline-[#FF3535] focus:outline-[0px]  border-[1px]'
                  }`}
                placeholder="_"
                maxLength={1}
                value={code4}
                onChange={(e) => {
                  handleInputChange(e, setCode4, code4, code5Ref);
                }}
              />

              <input
                type="number"
                ref={code5Ref}
                className={`input-mobile text-[24px]/[24px] h-[48px] flex items-center justify-center text-center placeholder-[#FFFFFF40] bg-[#121212] rounded-[12px]
                  ${
                    isCodeCorrect &&
                    codeInvalidMsg === '' &&
                    confirmCodeError === ''
                      ? 'focus:outline-white/50 focus:outline-[0px] '
                      : 'border-[#FF3535] focus:outline-[#FF3535] focus:outline-[0px]  border-[1px]'
                  }`}
                placeholder="_"
                maxLength={1}
                value={code5}
                onChange={(e) => {
                  handleInputChange(e, setCode5, code5, code6Ref);
                }}
              />

              <input
                type="number"
                ref={code6Ref}
                className={`input-mobile text-[24px]/[24px] h-[48px] flex items-center justify-center text-center placeholder-[#FFFFFF40] bg-[#121212] rounded-[12px]
                  ${
                    isCodeCorrect &&
                    codeInvalidMsg === '' &&
                    confirmCodeError === ''
                      ? 'focus:outline-white/50 focus:outline-[0px] '
                      : 'border-[#FF3535] focus:outline-[#FF3535] focus:outline-[0px]  border-[1px]'
                  }`}
                placeholder="_"
                maxLength={1}
                value={code6}
                onChange={(e) => {
                  handleInputChange(e, setCode6, code6, code6Ref);
                }}
              />
            </div>

            {(!isCodeCorrect && (
              <p className="flex items-center justify-center absolute text-[#FF3535] top-[52px] lg:top-[68px] left-[50%] translate-x-[-50%] text-[10px] sm:text-[12px]">
                The code must be 6 characters long
              </p>
            )) ||
              (codeInvalidMsg !== '' && (
                <p className="flex items-center absolute justify-center top-[52px] lg:top-[68px] left-[50%] translate-x-[-50%] text-[#FF3535] text-[10px] whitespace-nowrap sm:text-[12px]">
                  {codeInvalidMsg.toString()}
                </p>
              )) ||
              (confirmCodeError && (
                <p className="flex items-center absolute justify-center top-[52px] lg:top-[68px] left-[50%] translate-x-[-50%] text-[#FF3535] text-[10px] whitespace-nowrap sm:text-[12px]">
                  {confirmCodeError.toString()}
                </p>
              ))}
          </div>

          <div className="flex flex-col text-center text-[16px]/[24px] text-white/50 gap-[16px] mt-[8px]">
            <p>
              {t('we_sent_code.part1')}
              <span className="text-[#FF7100] text-[16px]/[24px]">
                {email.toString()}
              </span>
              {t('we_sent_code.part2')}
            </p>
            {/* <p>You can request a second code after 30 seconds</p> */}
            <p className="text-[#FF3535]">{t('please_check_spam')}</p>
          </div>
        </div>
        <div className="flex flex-col w-full gap-[16px]">
          <input
            className="h-[64px] flex text-center p2-desktop justify-center items-center w-full rounded-[42px] bg-orange transition-all lg:hover:bg-[#ff9330] active:scale-[97%] active:bg-[#b75800] cursor-pointer"
            type="submit"
            value={'Confirm'}
          />

          <button
            className="h-[64px] flex text-center p2-desktop justify-center items-center w-full rounded-[32px] hover:bg-[#232323] active:text-white/25"
            onClick={() => {
              setIsLoginOpened(true);
              setShowApprovalCodeModal(false);
            }}
          >
            {t('back')}
          </button>
        </div>
      </form>
    </div>
  );
}
