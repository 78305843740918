import { createSlice } from '@reduxjs/toolkit';
import { Prompts, BodyType } from '../types/tools';

const initialState = {
    NO: [
        Prompts.LINGERIE,
        Prompts.BIKINI,
        // Prompts.LATEX,
        // Prompts.NIGHTIE,
        // Prompts.STOCKINGS,
    ],
    STANDART: [],
    BASIC: [],
    PRO: [
        Prompts.UNDRESS,
        Prompts.SEX,
        Prompts.FOREST,
        Prompts.LATEX,
        Prompts.NIGHTIE,
        Prompts.STOCKINGS,
    ],
    BETA: [
        Prompts.FOREST,
    ],
    HOT: [
        Prompts.STOCKINGS,
        Prompts.UNDRESS,
    ],
    NEW: [
        Prompts.SEX,
    ],
    DAILY: [],
    bodyTypes: {
        NO:[
          BodyType.AUTO
        ],
        PRO:[
          BodyType.ATHLETIC,
          BodyType.CHUBBY,
          BodyType.SLIM,
          BodyType.SKINNY,
          BodyType.HOURGLASS,
        ],
        NEW:[],
        HOT:[],
        BETA:[],
        DAILY: [],
      }
}

export const promptSlice = createSlice({
    name: 'prompt',
    initialState,
    reducers: {
        updatePrompts: (state, action) => {
            const payload = action.payload;
            for (const key in payload) {
                if (Object.hasOwnProperty.call(payload, key)) {
                    state[key] = payload[key];
                }
            }
        },
        setDefaultPrompts: (state) => {
            return initialState;
        },
        setPromptsPRO: (state, action) => {
            state.PRO = [...action.payload];
        },
        setPromptsNO: (state, action) => {
            state.NO = [...action.payload];
        },
        setPromptsDAILY: (state, action) => {
            state.DAILY = [...action.payload];
        }
    },
})

export const { updatePrompts, setDefaultPrompts, setPromptsNO, setPromptsPRO, setPromptsDAILY } = promptSlice.actions


export default promptSlice.reducer