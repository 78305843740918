import Cookies from 'js-cookie';
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { STYLE_OVERFLOW } from '../../../constants/text';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { setIsOpenCookieModal } from '../../../redux/modalSlice';

const CookiesModal: React.FC = () => {
  const { t } = useTranslation();

  const isOpenCookieModal = useAppSelector(
    (state) => state.modalSlice.isOpenCookieModal
  );
  const dispatch = useAppDispatch();
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    if (isDetailsVisible) document.body.style.overflow = STYLE_OVERFLOW.HIDDEN;

    return () => {
      document.body.style.overflow = STYLE_OVERFLOW.VISIBLE;
    };
  }, [isDetailsVisible]);

  return (
    <>
      {isOpenCookieModal && (
        <div className="flex justify-between items-center p-1 cookies-notification text-[10px] fixed bottom-0 left-[40] w-full bg-[#393939] z-[500] animate-in fade-in duration-1000 rounded--[16px]">
          <p className="text-[20px]/[24px]  text-center ">
            {t('we_use')}
            <button
              className="text-orange underscore border-b-[1px] border-b-orange hover:border-b-[#FF9330] hover:text-[#FF9330]"
              onClick={() => {
                dispatch(setIsOpenCookieModal(false));
                setIsDetailsVisible(true);
              }}
            >
              {t('cookies')}
            </button>
          </p>
          <div className="w-[68%] text-[12px]/[14px] whitespace-nowrap font-black flex gap-[10px] items-center">
            <button
              onClick={() => {
                Cookies.set('cookies_accepted', 'true');
                dispatch(setIsOpenCookieModal(false));
              }}
              className="bg-orange hover:bg-[#FF9330] p-2 rounded-[16px] w-full"
            >
              {t('accept_all')}
            </button>
            <button
              onClick={() => {
                Cookies.set('cookies_declined', 'true');
                dispatch(setIsOpenCookieModal(false));
              }}
              className="bg-orange hover:bg-[#FF9330] p-2 rounded-[16px] w-full"
            >
              {t('continue_without_accepting')}
            </button>
          </div>
        </div>
      )}

      {isDetailsVisible && (
        <div className="z-50 fixed flex items-start xl:items-center justify-center top-0 left-0 w-screen h-screen bg-[#202020]/90 animate-in fade-in duration-400">
          <div
            className="fixed h-[inherit] md:h-fit flex items-center justify-center flex-col w-full p-6 xl:p-12 animate-in fade-in-5 slide-in-from-top-8 duration-1000"
            ref={modalRef}
          >
            <div className="bg-[#171717] overflow-scroll popup-cookies lg:rounded-lg p-9 flex items-center gap-[12px] flex-col h-full w-full max-w-[500px]">
              <h3 className="text-[28px]/[30px] text-center text-orange">
                Cookies Settings
              </h3>

              <p className="text-[14px] text-justify">
                This website and its partners, use cookies and similar
                technologies (collectively “technologies”) to store and access
                information on your device. Some of these technologies are
                technically essential to provide you with a secure,
                well-functioning and reliable website. We would also like to set
                optional/non-essential technologies to give you the best user
                experience. Optional technologies make it possible to measure
                the audience of our website, to display personalized advertising
                on third-party sites based on your profile, to carry out
                targeted marketing campaigns and to personalize the content of
                our website depending on your usage. Through these technologies,
                we will collect information such as your interaction with our
                website, your preferences and your browsing habits.
              </p>
              <p className="text-[14px] text-justify">
                If you are happy for technologies to be used for these purposes,
                click on "Accept All" to accept all of the technologies.
                Alternatively, you may click "Continue without accepting" to
                refuse all non-essential technologies.
              </p>

              <div className="flex-col md:flex-row w-full mt-[12px] text-[14px]/[14px] whitespace-nowrap font-black flex gap-[10px] items-center mb-[30px] md:mb-0">
                <button
                  onClick={() => {
                    Cookies.set('cookies_accepted', 'true');
                    dispatch(setIsOpenCookieModal(false));
                    setIsDetailsVisible(false);
                  }}
                  className="bg-orange hover:bg-[#FF9330] p-3 rounded-[16px] w-full"
                >
                  ACCEPT ALL
                </button>
                <button
                  onClick={() => {
                    Cookies.set('cookies_declined', 'true');
                    dispatch(setIsOpenCookieModal(false));
                    setIsDetailsVisible(false);
                  }}
                  className="bg-orange hover:bg-[#FF9330] p-3 rounded-[16px] w-full"
                >
                  CONTINUE WITHOUT ACCEPTING
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookiesModal;
