import React from 'react';

interface RightArrowProps {
  className?: string;
  width?: number | string;
  height?: number | string;
  fill?: string;
}
export const RightArrow: React.FC<RightArrowProps> = ({
  className,
  width = 10,
  height = 16,
  fill = 'white',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 16"
      fill={fill || 'white'}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M1.72916 15.4997L0.25 14.0205L6.29169 7.97882L0.25 1.93716L1.72916 0.458008L9.25 7.97882L1.72916 15.4997Z" />
    </svg>
  );
};
