import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { PROMPT_COOKIE } from '../constants/cookies';
import {
  BrushVariant,
  ImageQuality,
  SliderVariant,
  ToolsVariant,
} from '../constants/tools';
import { BodyType, Prompts } from '../types/tools';

export interface toolsSliceState {
  bodyType: BodyType;
  age: number;
  resolution: ImageQuality;
  mode: Prompts | null;
  toolsOpen: ToolsVariant | null;
  brushSize: number;
  brushVariant: BrushVariant | undefined;
  sliderVariant: SliderVariant | undefined;
  isUndo: boolean;
  trashState: boolean;
}

const getCurrentMode = () =>{
  if (localStorage.getItem('previousPath') === 'edit') {
    return Cookies.get(PROMPT_COOKIE) || null;
  } else {
    return null;
  }
}

const initialState: toolsSliceState = {
  bodyType: BodyType.AUTO,
  age: 0, //При переходе с слайдера на кнопки изменить число с 0 на 18
  resolution: ImageQuality.SD,
  mode: getCurrentMode() as toolsSliceState['mode'],
  toolsOpen: null,
  brushSize: 10,
  brushVariant: BrushVariant.BRUSH,
  sliderVariant: SliderVariant.UNDEFINED,
  isUndo: false,
  trashState: false,
};

export const toolsSlice = createSlice({
  name: 'tools',
  initialState,
  reducers: {
    setBodyType: (state, action: PayloadAction<BodyType>) => {
      state.bodyType = action.payload;
    },
    setAge: (state, action: PayloadAction<number>) => {
      state.age = action.payload;
    },
    setResolution: (state, action: PayloadAction<ImageQuality>) => {
      state.resolution = action.payload;
    },
    setMode: (state, action: PayloadAction<Prompts | null>) => {
      state.mode = action.payload;
    },
    setToolsOpen: (state, action: PayloadAction<ToolsVariant>) => {
      state.toolsOpen = action.payload;
    },
    setBrushSize: (state, action: PayloadAction<number>) => {
      state.brushSize = action.payload;
    },
    setBrushVariant: (state, action: PayloadAction<BrushVariant>) => {
      state.brushVariant = action.payload;
    },
    setSliderVariant: (state, action: PayloadAction<SliderVariant>) => {
      state.sliderVariant = action.payload;
    },
    setUndo: (state, action: PayloadAction<boolean>) => {
      state.isUndo = action.payload;
    },
    setTrashState: (state, action: PayloadAction<boolean>) => {
      state.trashState = action.payload;
    },
  },
});

export const {
  setBodyType,
  setAge,
  setResolution,
  setMode,
  setToolsOpen,
  setBrushSize,
  setBrushVariant,
  setSliderVariant,
  setUndo,
  setTrashState,
} = toolsSlice.actions;

export default toolsSlice.reducer;
