import Modal from 'components/common/Modal';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LANGUAGES } from '../../../constants/language';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { setIsOpenLanguageModal } from '../../../redux/modalSlice';

export interface LanguageModalProps {}
const LanguageModal: React.FC<LanguageModalProps> = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language.split('-')[0];

  const isOpenLanguageModal = useAppSelector(
    (state) => state.modalSlice.isOpenLanguageModal
  );
  const dispatch = useAppDispatch();

  const handleClose = useCallback(
    () => dispatch(setIsOpenLanguageModal(false)),
    []
  );
  return (
    <>
      <Modal
        isOpen={isOpenLanguageModal}
        onClose={handleClose}
        classname="p-0"
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="flex w-full max-w-[500px] flex-col items-center justify-between rounded-[16px] py-5 md:px-12"
        >
          <p className="flex justify-center text-center text-[30px] font-semibold text-white">
            Change language
          </p>
          <div className="line m-3 h-[2px] w-full bg-white/10 "></div>
          {LANGUAGES.map((v) => (
            <a
              key={v.codeName}
              className={`m-1 flex-grow cursor-pointer rounded-full ${
                v.codeName === currentLanguage && 'bg-orange'
              }`}
              style={{ width: '100%' }}
              href={`?lng=${v.codeName}`}
            >
              <p
                style={{
                  justifyContent: 'space-between',
                }}
                className="m-1 flex h-auto grow items-center px-4 text-[18px] font-semibold"
              >
                {v.fullName}
                <svg
                  style={{}}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12 21.6C6.696 21.6 2.4 17.304 2.4 12C2.4 6.696 6.696 2.4 12 2.4C17.304 2.4 21.6 6.696 21.6 12C21.6 17.304 17.304 21.6 12 21.6Z"
                    fill="white"
                  />
                  {v.codeName === currentLanguage && (
                    <path
                      d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z"
                      fill="white"
                    />
                  )}
                </svg>
              </p>
            </a>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default LanguageModal;
