import AuthorizationContext from 'context/authorizationContext';
import Cookies from 'js-cookie';
import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { MAIN_EDITOR_URL, SECONDARY_EDITOR_URL } from 'utils/text';

import { sendUserInfoRequest } from '../packets/user/UserInfoRequestPacket';
import { updateUserInfo } from '../redux/userSlice';

export const useAuthorization = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { authorized, setAuthorized } = useContext(AuthorizationContext);

  useEffect(() => {
    const checkAuthorization = async () => {
      if (Cookies.get('token') !== undefined) {
        await sendUserInfoRequest()
          .then((response) => {
            setAuthorized(true);
            dispatch(updateUserInfo(response.data));
            // if (pathname !== '/new-edit' && pathname !== '/undress-ai') {
            //   const { experimentId } = response.data;
            //   const shouldRedirect = Math.abs(experimentId) % 100 < 25;
            //   if (shouldRedirect) {
            //     navigate('/new-edit');
            //     localStorage.setItem(MAIN_EDITOR_URL, '/new-edit');
            //     sessionStorage.setItem(SECONDARY_EDITOR_URL, '/edit');
            //   } else {
            //     navigate('/edit');
            // localStorage.setItem(MAIN_EDITOR_URL, '/edit');
            //     sessionStorage.setItem(SECONDARY_EDITOR_URL, '/new-edit');
            //   }
            // }
          })
          .catch((e) => {
            setAuthorized(false);
            switch (pathname) {
              case '/new-edit':
              case '/auth':
              case '/undress-ai':
              case '/undress-ai/':
              case '/undress-ai/auth':
                break;
              default:
                navigate('/');
            }
            console.log('error', e);
          });
      } else {
        setAuthorized(false);
        if (
          pathname !== '/auth' &&
          pathname !== '/undress-ai/' &&
          pathname !== '/undress-ai' &&
          pathname !== '/undress-ai/auth'
        ) {
          navigate('/');
        }
      }
    };

    checkAuthorization();
  }, []);

  return { authorized };
};
