import Modal from 'components/common/Modal';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { deleteMessage } from '../../../packets/user/UserInfoRequestPacket.js';
import { clearMessage } from '../../../redux/messageSlice';
import Button from '../../common/Button';

const MessageModal: React.FC = () => {
  const { t } = useTranslation();

  const messageId = useAppSelector((state) => state.messageSlice.id);
  const messageText = useAppSelector((state) => state.messageSlice.message);
  const isOpenModal = useAppSelector(
    (state) => state.messageSlice.isOpenMessageModal
  );
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    deleteMessage(messageId);
    dispatch(clearMessage());
  }, [messageId]);

  const viewMessage = useMemo(() => {
    // return "We are sorry that you did not like the result. We grant to you <span class='text-[#FFCB46]'>+1 credit</span> and you can try again.You may check the <Link to='instruction'>instruction</Link> to get best results.";
    if (!messageText) return '';
    const [type, message] = messageText.split(':');
    if (type === 'CARD_ERROR') {
      return `Your last payment was <span class='text-red-600'>DECLINED</span>. Reason:<br/> <span class='text-base text-red-600'>${message}</span>`;
    } else if (type === 'TRANS') {
      return t(message);
    } else {
      return message;
    }
  }, [messageText]);

  return (
    <Modal
      isOpen={isOpenModal}
      onClose={handleClose}
    >
      <div className="w-full max-w-[500px] items-center">
        <div
          className="text-sm text-normal"
          dangerouslySetInnerHTML={{ __html: viewMessage }}
        ></div>
        <div className="flex flex-col justify-center gap-3 pt-6">
          <Button onClick={handleClose}>OK</Button>
        </div>
      </div>
    </Modal>
  );
};

export default MessageModal;
