import { createSlice } from '@reduxjs/toolkit';

export const fileUploader = createSlice({
  name: 'fileUploader',
  initialState: {
    value: false,
  },
  reducers: {
    requestUploadNewFile: (state, action) => {
      state.value = !!action.payload;
    },
  },
});

export const { requestUploadNewFile } = fileUploader.actions;

export default fileUploader.reducer;
