import { createSlice } from '@reduxjs/toolkit';

import { FEATURES_TYPES } from '../constants/text';

const API_HOST =
  location?.host?.indexOf('undressit.dev') !== -1 ||
  location?.host?.indexOf('localhost:3000') !== -1
    ? 'https://api.undressit.dev'
    : 'https://api.nudify.online';

const APPLE_CLIENT_ID =
  location?.host?.indexOf('undressit.dev') !== -1 ||
  location?.host?.indexOf('localhost:3000') !== -1
    ? 'dev.undressit'
    : 'online.nudify.api';

const APPLE_REDIRECT_URL =
  location?.host?.indexOf('undressit.dev') !== -1 ||
  location?.host?.indexOf('localhost:3000') !== -1
    ? 'https://undressit.dev'
    : 'https://api.nudify.online';

const initialState = {
  isUploadAvailable: true,
  isAdultAvailable: false,
  baseUrl: API_HOST,
};

export const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    updateFeatures: (state, action) => {
      const featuresArray = action?.payload?.features || action.payload;
      const userIp = action?.payload?.userIp;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (window.initializeYM) {
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          window.initializeYM(userIp);
        } catch (e) {
          console.error('initializeYM', e);
        }
      }
      state.isUploadAvailable = featuresArray.includes(
        FEATURES_TYPES.UPLOAD_CONTENT
      );
      state.isAdultAvailable = featuresArray.includes(
        FEATURES_TYPES.ADULT_CONTENT
      );
      state.baseUrl = !featuresArray.includes(FEATURES_TYPES.UPLOAD_CONTENT)
        ? 'https://api.undressit.dev'
        : state.baseUrl;
    },
    clearFeatures: () => {
      initialState;
    },
  },
});

export const { updateFeatures, clearFeatures } = featuresSlice.actions;

export default featuresSlice.reducer;
