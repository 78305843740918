import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from 'utils/utils';

import { STYLE_OVERFLOW } from '../../../constants/text';
import { MAIN_EDITOR_URL } from '../../../utils/text';

export default function VerificationComplete() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const handleBackButton = () => {
      navigate(localStorage.getItem(MAIN_EDITOR_URL) || "/edit");
    };

    document.body.style.overflow = STYLE_OVERFLOW.HIDDEN;
    window.addEventListener('popstate', handleBackButton);
    return () => {
      document.body.style.overflow = STYLE_OVERFLOW.VISIBLE;
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  return (
    <div className="fixed flex auth-login verification-complete items-center h-full lg:h-auto justify-center flex-col w-full animate-in fade-in-5 duration-1000">
      <div className="flex flex-col h-full gap-[48px] p-[24px] lg:px-0 w-full lg:w-6/12 xl:w-5/12 2xl:w-4/12 lg:rounded-[32px] items-center justify-between">
        <div className="flex flex-col gap-[16px] text-center">
          <h2 className="text-[32px]/[32px] mb-[16px]">
            {t('verification_complete')}
          </h2>

          <p className="text-[16px]/[24px] text-white/50">
            {t('your_email_linked')}
          </p>
        </div>

        <button
          className="h-[64px] min-h-[64px] proceed-auth flex text-center p2-desktop justify-center items-center w-full rounded-[42px] bg-orange transition-all lg:hover:bg-[#ff9330] active:scale-[97%] active:bg-[#b75800] cursor-pointer"
          onClick={() => {
            navigate(localStorage.getItem(MAIN_EDITOR_URL) || "/edit");
          }}
        >
          {t('go_to_editor')}
        </button>
      </div>
    </div>
  );
}
