import { useMemo } from 'react';

function useDevelopment() {
  const host = window.location?.host;

  const {
    isTrumpModeOn,
    isCustomModeOn,
    isAdvancedGenerationModes,
    isPhoneTrialModeOn,
    isAppleLoginVisible,
    isIpTrial,
    isUrlAuthButton
  } = useMemo(() => {
    switch (host) {
      case 'magicphoto.app':
        return {
          isPhoneTrialModeOn: false,
          isTrumpModeOn: false,
          isAdvancedGenerationModes: false,
          isAppleLoginVisible: false,
          isIpTrial: false,
          isCustomModeOn: true,
          isUrlAuthButton: false,
        };
      case 'localhost:3000':
      case 'undressit.dev':
        return {
          isPhoneTrialModeOn: true,
          isTrumpModeOn: true,
          isAdvancedGenerationModes: true,
          isAppleLoginVisible: false,
          isIpTrial: true,
          isCustomModeOn: true,
          isUrlAuthButton: true
        };
      default:
        return {
          isPhoneTrialModeOn: false,
          isTrumpModeOn: false,
          isAdvancedGenerationModes: false,
          isAppleLoginVisible: false,
          isIpTrial: false,
          isCustomModeOn: true,
          isUrlAuthButton: false
        };
    }
  }, [host]);

  return {
    isCustomModeOn,
    isTrumpModeOn,
    isAdvancedGenerationModes,
    isPhoneTrialModeOn,
    isAppleLoginVisible,
    isIpTrial,
    isUrlAuthButton,
  };
}

export default useDevelopment;
