import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from './common/Button';
import ButtonV2 from './common/ButtonV2';
import { useAppSelector } from '../hooks/redux';
import { clearError } from '../redux/errorSlice';

const ErrorMessage = () => {
  const errorSlice = useAppSelector((state) => state.errorSlice);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClearError = useCallback(() => {
    setIsVisible(false);
    setTimeout(() => dispatch(clearError()), 500);
  }, [dispatch]);

  const [isVisible, setIsVisible] = useState(true);
  const animationClass = isVisible ? 'slide-down' : 'slide-up';

  const errorText = () => {
    const error = errorSlice?.error || 'unknown error';
    switch (error) {
      case 'use_vpn':
        return t('use_vpn');
      default:
        return t('unknown_error');
    }
  };

  if (!errorSlice?.error) {
    return null;
  }

  return (
    <div
      className={`fixed top-0 left-0 w-full h-1/6 bg-[#b142ff] z-50 flex flex-col mmd:flex-row justify-between ${animationClass}`}
    >
      <p className="p-3 flex items-center">{errorText()}</p>
      <div className="flex items-center mmd:p-3 pl-3 pb-3 justify-end">
        {window.location.pathname === '/new-edit' ? (
          <ButtonV2
            primary
            onClick={handleClearError}
          >
            {t('close')}
          </ButtonV2>
        ) : (
          <Button onClick={handleClearError}>{t('close')}</Button>
        )}
      </div>
    </div>
  );
};

export default ErrorMessage;
