import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const autoMask = createSlice({
  name: 'autoMask',
  initialState: {
    value: Cookies.get('automask') || 'auto',
    displayHelp: Cookies.get('automaskHelp') !== 'false',
  },
  reducers: {
    switchAutoMakeMode: (state, action) => {
      Cookies.set('automask', action.payload);
      state.value = action.payload;
    },
    setAutoMaskDisplayHelp: (state, action) => {
      Cookies.set('automaskHelp', (!!action.payload).toString());
      state.displayHelp = !!action.payload;
    },
  },
});

export const { switchAutoMakeMode, setAutoMaskDisplayHelp } = autoMask.actions;

export default autoMask.reducer;
