import { configureStore } from '@reduxjs/toolkit';

import autoMaskReducer from './autoMaskReducer';
import canvasSlice from './canvasSlice';
import errorMiddleware from './errorMiddleware.js';
import errorSlice from './errorSlice';
import featuresSlice from './featuresSlice';
import fileUploaderReducer from './fileUploaderReducer';
import messageSlice from './messageSlice';
import modalSlice from './modalSlice';
import paymentSlice from './paymentSlice';
import queueSlice from './queueSlice';
import toolsSlice from './toolsSlice';
import userAddressinfo from './userAddressinfo';
import userSlice from './userSlice';
import promptSlice from './promptSlice';

export const store = configureStore({
  reducer: {
    errorSlice,
    autoMaskReducer,
    fileUploaderReducer,
    user: userSlice,
    features: featuresSlice,
    payment: paymentSlice,
    modalSlice,
    toolsSlice,
    canvas: canvasSlice,
    queue: queueSlice,
    messageSlice,
    userAddressinfo,
    prompt: promptSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(errorMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
