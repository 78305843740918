import Cookies from 'js-cookie';
import { useState, useRef, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { validateEmail } from 'utils/utils.js';

import { STYLE_OVERFLOW } from '../../../constants/text';
import { customMetric } from '../../../packets/user/UserInfoRequestPacket';

export default function AccountRecoveryPopup({
  setIsLoginOpened,
  setShowRecoveryModal,
  email,
  setEmail,
  handleSendRecoveryEmail,
  recoveryEmailError,
  closeModal,
}) {
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isCaptchaSolved, setIsCaptchaSolved] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(Cookies.get('captcha-dev'));
  const { t } = useTranslation();
  const recaptchaRef = useRef(null);
  const modalRef = useRef();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const onCaptchaSolved = async (token) => {
    Cookies.set('captcha_token', token);
    setIsCaptchaSolved(true);
    customMetric('captcha_solved');
    setCaptchaToken(token);
  };

  const handleSendClick = () => {
    let EmailValid = validateEmail(email);

    setIsEmailValid(EmailValid);

    if (EmailValid) {
      handleSendRecoveryEmail();
    } else {
      // recaptchaRef.current.reset();
    }
  };

  useEffect(() => {
    const handleBackButton = () => {
      setIsLoginOpened(true);
      setShowRecoveryModal(false);
    };

    document.body.style.overflow = STYLE_OVERFLOW.HIDDEN;
    window.addEventListener('popstate', handleBackButton);
    return () => {
      document.body.style.overflow = STYLE_OVERFLOW.VISIBLE;
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  return (
    <div className="fixed flex auth-login auth-recovery items-center h-full lg:h-auto justify-center flex-col w-full animate-in fade-in-5 duration-1000">
      <form
        className="flex flex-col h-full gap-[24px] p-[24px] lg:px-0 w-full lg:w-6/12 xl:w-5/12 2xl:w-4/12 lg:rounded-[32px] items-center justify-between"
        onSubmit={(e) => {
          e.preventDefault();
          handleSendClick();
        }}
      >
        <div className="flex items-center w-full flex-col">
          <h2 className="text-[32px]/[32px] mb-[32px]">{t('Verification')}</h2>

          <p className="text-center text-[16px]/[24px] text-white/50 mb-[16px] ">
            {t('make_sure')}
          </p>
          <div className="w-full relative">
            <input
              type="email"
              className={`px-[24px] w-full h-[64px] rounded-[32px] p2-desktop bg-[#121212] placeholder-white/25 ${
                isEmailValid ? '' : 'border-[1px] pl-[23px] border-[#FF3535]'
              }`}
              placeholder="Enter your e-mail"
              value={email}
              onChange={handleEmailChange}
              style={{ fontWeight: 600 }}
            />

            {(!isEmailValid && (
              <p className="pl-[24px] absolute top-[68px] text-[#FF3535] text-[10px] sm:text-[12px]">
                Please enter a valid email address
              </p>
            )) ||
              (recoveryEmailError && (
                <p className="pl-[24px] absolute top-[68px] text-[#FF3535] text-[10px] sm:text-[12px]">
                  {recoveryEmailError}
                </p>
              ))}
          </div>
        </div>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6Ld6jlMpAAAAAM3S9KEMLptVzcrkuDf_ebOWyJZK"
          onChange={onCaptchaSolved}
          className="m-4 flex w-full items-center justify-center"
        />

        <div className="flex margin-recovery flex-col gap-[16px] w-full">
          <input
            className="h-[64px] flex text-center p2-desktop justify-center items-center w-full rounded-[32px] bg-orange transition-all lg:hover:bg-[#ff9330] active:scale-[97%] active:bg-[#b75800] cursor-pointer"
            type="submit"
            value={'Send code'}
          />
          <button
            disabled={!isCaptchaSolved}
            className="h-[64px] flex text-center p2-desktop justify-center items-center w-full rounded-[32px] hover:bg-[#232323] active:text-white/25"
            onClick={() => {
              setIsLoginOpened(true);
              setShowRecoveryModal(false);
            }}
          >
            {t('back')}
          </button>
        </div>
      </form>
    </div>
  );
}
