import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { validatePassword } from 'utils/utils.js';

import { STYLE_OVERFLOW } from '../../../constants/text';

export default function NewPasswordPopup({
  setShowNewPasswordPopup,
  password,
  setPassword,
  handleRecoverPassword,
  newPasswordError,
}) {
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [doPasswordsMatch, setDoPasswordsMatch] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    const handleBackButton = () => {
      setShowNewPasswordPopup(false);
    };

    window.addEventListener('popstate', handleBackButton);
    document.body.style.overflow = STYLE_OVERFLOW.HIDDEN;

    return () => {
      document.body.style.overflow = STYLE_OVERFLOW.VISIBLE;
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleChangePasswordClick = () => {
    const passwordsMatch = confirmPassword === password;
    const passwordValid = validatePassword(password);

    setIsPasswordValid(passwordValid);
    setDoPasswordsMatch(passwordsMatch);

    if (passwordValid && passwordsMatch) {
      handleRecoverPassword();
    }
  };

  return (
    <div className="fixed flex items-center h-full lg:h-auto justify-center flex-col w-full animate-in fade-in-5 duration-1000">
      <form
        className="flex auth-login new-pass-auth h-full lg:h-auto flex-col gap-[28px] px-[24px] lg:px-0 py-[24px] w-full lg:w-6/12 xl:w-5/12 2xl:w-4/12 rounded-[32px] items-center justify-between bg-[#000000]"
        onSubmit={(e) => {
          e.preventDefault();
          handleChangePasswordClick();
        }}
      >
        <div className="flex items-center w-full flex-col">
          <h2 className="text-[32px]/[32px] text-center mb-[32px] lg:mb-[48px]">
            {t('set_new_password')}
          </h2>

          <div className="w-full relative mb-[28px]">
            <input
              type="password"
              className={`px-[24px] w-full h-[64px] p2-desktop rounded-full bg-[#121212] text-[16px] placeholder-[#FFFFFF40] placeholder-[14px] ${
                isPasswordValid ? '' : 'border-[1px] pl-[23px] border-[#FF3535]'
              }`}
              placeholder={t('enter_password')}
              value={password}
              onChange={handlePasswordChange}
              style={{ fontWeight: 600 }}
            />

            {!isPasswordValid && (
              <p className="pl-[24px] absolute top-[68px] text-[#FF3535] text-[10px] sm:text-[12px]">
                {t('login_errors.error1')}
              </p>
            )}
          </div>

          <div className="w-full relative">
            <input
              type="password"
              className={`px-[24px] w-full h-[64px] p2-desktop rounded-full bg-[#121212] text-[16px] placeholder-[#FFFFFF40] placeholder-[14px] ${
                !doPasswordsMatch || !isPasswordValid
                  ? 'border-[1px] pl-[23px] border-[#FF3535]'
                  : ''
              }`}
              placeholder={t('confirm_new_password')}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              style={{ fontWeight: 600 }}
            />

            {(!doPasswordsMatch && (
              <p className="pl-[24px] absolute top-[68px] text-[#FF3535] text-[10px] sm:text-[12px]">
                {t('login_errors.error5')}
              </p>
            )) ||
              (newPasswordError && (
                <p className="pl-[24px] absolute top-[68px] text-[#FF3535] text-[10px] sm:text-[12px]">
                  {newPasswordError}
                </p>
              ))}
          </div>
        </div>

        <div className="w-full new-pass-block flex flex-col gap-[16px]">
          <input
            className="h-[64px] min-h-[64px] flex text-center p2-desktop justify-center items-center w-full rounded-[32px] bg-[#FF7100] transition-all lg:hover:bg-[#ff9330] active:scale-[97%] active:bg-[#b75800] cursor-pointer"
            type="submit"
            value={'Change password'}
          />

          <button
            className="h-[64px] min-h-[64px] flex text-center p2-desktop justify-center items-center w-full rounded-[32px] hover:bg-[#232323] active:text-white/25"
            onClick={(event) => {
              event.preventDefault();
              setShowNewPasswordPopup(false);
            }}
          >
            {t('close')}
          </button>
        </div>
      </form>
    </div>
  );
}
