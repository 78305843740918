import { createSlice } from '@reduxjs/toolkit';

export const messageSlice = createSlice({
  name: 'error',
  initialState: {
    id: null,
    message: null,
    isOpenMessageModal: false,
  },
  reducers: {
    setMessage: (state, action) => {
        if (state.id !== action.payload.id) {
            state.id = action.payload.id;
            state.message = action.payload.message;
            state.isOpenMessageModal = true;
        }
    },
    clearMessage: (state) => {
      state.id = null;
      state.message = null;
      state.isOpenMessageModal = false;
    },
  },
});

export const { setMessage, clearMessage } =
  messageSlice.actions;

export default messageSlice.reducer;
