import {useEffect, useRef, useState} from "react";
import {getCaptchaBase64} from "../../packets/user/UserInfoRequestPacket";

export default function SimpleCaptcha({onChange, captchaToken}) {
    const [captchaImg, setCaptchaImgImg] = useState(null);
    const [error, setError] = useState('');
    const [idCaptcha, setIdCaptcha] = useState('');
    const inputRef = useRef(null);

    useEffect(()=>{
        console.log("captchaToken", captchaToken);
        if (!captchaToken) {
            setError('');
            getCaptchaBase64().then(x => {
                console.log(x.data);
                const {id, image} = x.data;
                inputRef.current.value = '';
                setIdCaptcha(id);
                setCaptchaImgImg('data:image/png;base64,' + image);
            }).catch((e) => {
                console.log(e);
                setError("Can't get captcha image")
            })
        } else {
            onChange(idCaptcha + '|' + inputRef.current.value);
        }
    }, [captchaToken])

    const handleAnswerChange = (event) => {
        onChange(idCaptcha + '|' + event.target.value);
    };

    return (<>
        <div className="w-full relative">
            {captchaImg ? <img src={captchaImg} alt="CAPTCHA" /> : <p>Loading...</p>}
        </div>
        <div className="w-full relative">
            <input
                ref={inputRef}
                type="text"
                className={`px-[24px] w-full p2-desktop h-[64px] rounded-full bg-[#121212] text-[16px] placeholder-[#FFFFFF40] placeholder-[14px]`}
                placeholder={'type symbols from image'}
                onChange={handleAnswerChange}
                style={{ fontWeight: 600 }}
            />
        </div>
    </>);
}