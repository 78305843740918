import classnames from 'classnames';
import React from 'react';
import { Modal as UIModal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './index.css';

export interface IModalProps {
  isOpen: boolean;
  onClose?: () => void;
  classname?: string;
  headerText?: string;
  children: React.ReactNode;
  closeOnOverlayClick?: boolean;
  showCloseIcon?: boolean;
  center?: boolean;
  containerClassName?: string;
  closeButtonClassName?: string;
}

const closeIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="23"
      height="23"
      rx="11.5"
      stroke="#434247"
    />
    <path
      d="M15.5 9.205L14.795 8.5L12 11.295L9.205 8.5L8.5 9.205L11.295 12L8.5 14.795L9.205 15.5L12 12.705L14.795 15.5L15.5 14.795L12.705 12L15.5 9.205Z"
      fill="#C8C7CE"
    />
  </svg>
);

const Modal: React.FC<IModalProps> = ({
  isOpen,
  onClose,
  children,
  classname = 'customModal',
  closeOnOverlayClick = true,
  showCloseIcon = true,
  center = true,
  containerClassName,
  closeButtonClassName,
}) => {
  return (
    <UIModal
      open={isOpen}
      onClose={onClose}
      center={center}
      closeOnEsc={false}
      closeOnOverlayClick={closeOnOverlayClick}
      showCloseIcon={showCloseIcon}
      classNames={{
        modal: classnames('customModal', classname),
        closeButton: classnames('modalCloseButton', closeButtonClassName),
        modalContainer: containerClassName,
      }}
      closeIcon={closeIcon}
    >
      {children}
    </UIModal>
  );
};

export default Modal;
