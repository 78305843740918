import { BodyType } from '../types/tools';

export const BodyTypeToImageMap: Record<BodyType, string> = {
  [BodyType.AUTO]: '/bodytype/none.webp',
  [BodyType.SKINNY]: '/bodytype/skinny.webp',
  [BodyType.SLIM]: '/bodytype/slim.webp',
  [BodyType.HOURGLASS]: '/bodytype/hourglass.webp',
  // [BodyType.CURVY]: '/bodytypecurvy/.webp',
  [BodyType.ATHLETIC]: '/bodytype/athletic.webp',
  [BodyType.CHUBBY]: '/bodytype/chubby.webp',
};

export enum ImageQuality {
  SD = 'SD',
  HD = 'HD',
  UHD = 'UHD',
}

export enum ToolsVariant {
  MODE = 'mode',
  SETTINGS = 'settings',
}

export enum BrushVariant {
  BRUSH = 'brush',
  RUBBER = 'rubber',
  AUTOHL = 'autoHL',
}

export enum SliderVariant {
  BRUSH_SIZE = 'brushsize',
  RUBBER_SIZE = 'rubbersize',
  BODY_TYPE = 'bodyType',
  AGE = 'age',
  UNDEFINED = undefined,
}
