import { GoogleOAuthProvider } from '@react-oauth/google';
import Cookies from 'js-cookie';
import { useState, useRef, useEffect, useCallback } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { STYLE_OVERFLOW } from '../../../constants/text';
import { customMetric } from '../../../packets/user/UserInfoRequestPacket';
import { MAIN_EDITOR_URL } from '../../../utils/text';
import { validateEmail, validatePassword } from '../../../utils/utils.js';
import { GoogleLoginButton } from '../GoogleLoginButton';
import SimpleCaptcha from '../../captcha/SimpleCaptcha';

export default function SignUpPopup({
  setCaptchaToken,
  closeModal,
  handleLogin,
  email,
  password,
  setEmail,
  setPassword,
  isUserRegistered,
  captchaToken,
  emailVerificationError,
  setEmailVerificationError,
}) {
  const modalRef = useRef();
  const navigate = useNavigate();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [confirmEmail, setConfirmEmail] = useState('');
  const [doEmailsMatch, setDoEmailsMatch] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [doPasswordsMatch, setDoPasswordsMatch] = useState(true);
  const [localCaptcha, setLocalCaptcha] = useState('');
  const [captchaFailed, setCaptchaFailed] = useState(true);
  const recaptchaRef = useRef(null);
  const { t } = useTranslation();

  const handleValidateEmail = useCallback(() => {
    if (email.length > 0) {
      setEmailVerificationError('');
      const emailValid = validateEmail(email);
      setIsEmailValid(emailValid);
    }
  }, [email, confirmEmail]);

  const outsideClick = (e) => {
    if (modalRef.current === e.target) {
      closeModal();
    }
  };

  useEffect(() => {
    addEventListener('mousedown', outsideClick);
    document.body.style.overflow = STYLE_OVERFLOW.HIDDEN;

    return () => {
      removeEventListener('mousedown', outsideClick);
      document.body.style.overflow = STYLE_OVERFLOW.VISIBLE;
    };
  }, []);

  useEffect(() => {
    if (emailVerificationError != '') {
      // recaptchaRef.current.reset();
      setCaptchaFailed(true);
    }
  }, [emailVerificationError]);

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleConfirmEmailChange = (event) => {
    setConfirmEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmEmailBlur = () => {
    setDoEmailsMatch(email === confirmEmail);
  };

  const onCaptchaSolved = async (token) => {
    // customMetric('captcha_solved');
    setCaptchaToken(token);
  };

  const handleLoginClick = (captcha) => {
    onCaptchaSolved(captcha);
    const passwordsMatch = confirmPassword === password;
    const passwordValid = validatePassword(password);
    handleValidateEmail();
    setDoEmailsMatch(email === confirmEmail);
    setIsPasswordValid(passwordValid);
    setDoPasswordsMatch(passwordsMatch);

    if (isEmailValid && passwordValid && passwordsMatch && doEmailsMatch) {
      handleLogin(email, password, captcha);
    } else {
      // recaptchaRef.current.reset();
      setCaptchaFailed(true);
    }
  };

  return (
    <>
      <form
        className="w-full flex items-center justify-between h-full flex-col gap-[16px]"
        onSubmit={(event) => {
          event.preventDefault();
          handleLoginClick(localCaptcha);
        }}
      >
        <div className="flex flex-col w-full gap-[28px]">
          <div className="flex items-center w-full flex-col gap-[28px]">
            <div className="w-full relative">
              <input
                type="email"
                className={`px-[24px] w-full p2-desktop h-[64px] rounded-full bg-[#121212] text-[16px] placeholder-[#FFFFFF40] placeholder-[14px] ${
                  isEmailValid && !emailVerificationError
                    ? ''
                    : 'border-[1px] pl-[23px] border-[#FF3535]'
                }`}
                placeholder="Enter your e-mail"
                value={email}
                onChange={handleEmailChange}
                onBlur={handleValidateEmail}
                style={{ fontWeight: 600 }}
              />
              {(emailVerificationError && (
                <p className="pl-[24px] absolute top-[68px] text-[#FF3535] text-[10px] sm:text-[12px]">
                  {emailVerificationError}
                </p>
              )) ||
                (!isEmailValid && (
                  <p className="pl-[24px] absolute top-[68px] text-[#FF3535] text-[10px] sm:text-[12px]">
                    {t('please_enter_email')}
                  </p>
                ))}
            </div>

            <div className="w-full relative">
              <input
                type="email"
                className={`px-[24px] w-full p2-desktop h-[64px] rounded-full bg-[#121212] text-[16px] placeholder-[#FFFFFF40] placeholder-[14px] ${
                  doEmailsMatch ? '' : 'border-[1px] pl-[23px] border-[#FF3535]'
                }`}
                placeholder={t('confirm_email_placeholder')}
                value={confirmEmail}
                onChange={handleConfirmEmailChange}
                onBlur={handleConfirmEmailBlur}
                style={{ fontWeight: 600 }}
              />
              {!doEmailsMatch && (
                <p className="pl-[24px] absolute top-[68px] text-[#FF3535] text-[10px] sm:text-[12px]">
                  {t('emails_not_match')}
                </p>
              )}
            </div>

            <div className="w-full relative">
              <input
                type="password"
                className={`px-[24px] w-full p2-desktop h-[64px] rounded-full bg-[#121212] text-[16px] placeholder-[#FFFFFF40] placeholder-[14px] ${
                  isPasswordValid
                    ? ''
                    : 'border-[1px] pl-[23px] border-[#FF3535]'
                }`}
                placeholder="Enter your password"
                value={password}
                onChange={handlePasswordChange}
                style={{ fontWeight: 600 }}
              />

              {!isPasswordValid && (
                <p className="pl-[24px] absolute top-[68px] text-[#FF3535] text-[10px] sm:text-[12px]">
                  {t('login_errors.error1')}
                </p>
              )}
            </div>

            <div className="w-full relative">
              <input
                type="password"
                className={`px-[24px] w-full p2-desktop h-[64px] rounded-full bg-[#121212] text-[16px] placeholder-[#FFFFFF40] placeholder-[14px] ${
                  doPasswordsMatch
                    ? ''
                    : 'border-[1px] pl-[23px] border-[#FF3535]'
                }`}
                placeholder={t('confirm_new_password')}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                style={{ fontWeight: 600 }}
              />

              {(!doPasswordsMatch && (
                <p className="pl-[24px] absolute top-[68px] text-[#FF3535] text-[10px] sm:text-[12px]">
                  {t('login_errors.error5')}
                </p>
              )) ||
                (isUserRegistered === 'true' && (
                  <p className="pl-[24px] absolute top-[68px] text-[#FF3535] text-[10px] sm:text-[12px]">
                    {t('user_already_registered')}
                  </p>
                ))}
            </div>
          </div>
          <div className="relative w-full flex justify-center">
            <SimpleCaptcha captchaToken={captchaToken} onChange={(token) =>{
                  setLocalCaptcha(token);
                  setCaptchaFailed(false);
            }} />
            {/*<ReCAPTCHA*/}
            {/*  ref={recaptchaRef}*/}
            {/*  sitekey="6Ld6jlMpAAAAAM3S9KEMLptVzcrkuDf_ebOWyJZK"*/}
            {/*  onChange={(token) => {*/}
            {/*    setLocalCaptcha(token);*/}
            {/*    setCaptchaFailed(false);*/}
            {/*  }}*/}
            {/*/>*/}
          </div>

          <div className="relative w-full text-center flex flex-col items-center justify-between lg:justify-center gap-[16px]">
            <input
              className={`h-[64px] flex text-center p2-desktop justify-center items-center w-full rounded-[42px] ${captchaFailed ? 'bg-[#6f4d2c]' : 'bg-orange'} transition-all ${captchaFailed ? '' : 'lg:hover:bg-[#ff9330]'} active:scale-[97%] active:bg-[#b75800] cursor-pointer`}
              type="submit"
              value={'Sign Up'}
              onClick={handleLoginClick}
              disabled={captchaFailed}
            />
            <p>or</p>
            <GoogleOAuthProvider clientId="820468064502-4segq1mmvp0o7tn8906p6f9dngrhd6ha.apps.googleusercontent.com">
              <GoogleLoginButton
                onSuccess={() => {
                  navigate(localStorage.getItem(MAIN_EDITOR_URL) || "/edit");
                }}
                onFail={(data) => {
                  if (data?.title?.indexOf('CaptchaToken is invalid') !== -1) {
                    alert('6. Captcha expired! Please solve the Captcha again');
                    setCaptchaToken(null);
                  } else {
                    try {
                      alert(
                        'Unknown error: ' + typeof data === 'object'
                          ? JSON.stringify(data)
                          : data
                      );
                    } catch (e) {
                      console.log('error', e);
                    }
                  }
                }}
                captchaToken={captchaToken}
              />
            </GoogleOAuthProvider>
          </div>
        </div>

        <button
          className="h-[64px] min-h-[64px] flex text-center p2-desktop justify-center items-center w-full rounded-[32px] hover:bg-[#232323] active:text-white/25"
          onClick={closeModal}
        >
          {t('back')}
        </button>
      </form>
    </>
  );
}
