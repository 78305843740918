/* global heic2any */
import imageCompression from 'browser-image-compression';
import sbjs from 'sourcebuster';

function loadHeic2Any() {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = '/js/heic2any.min.js';
    script.async = true;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
}

export const validateEmail = (email) => {
  const emailPattern =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return emailPattern.test(email);
};

export const validatePassword = (password) => {
  const passwordPattern = /^.{6,20}$/;
  return passwordPattern.test(password);
};

function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

export const compressImage = async (dataurlOrFile, filename = '') => {
  return new Promise(async (x) => {
    const mobileOptions = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1440,
      useWebWorker: true,
      fileType: 'image/jpeg',
      initialQuality: 0.8,
    };
    const desktopOptions = {
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      fileType: 'image/jpeg',
      initialQuality: 0.8,
    };

    const isFile = dataurlOrFile instanceof File;

    let file = isFile
      ? dataurlOrFile
      : await imageCompression.getFilefromDataUrl(dataurlOrFile, filename);

    if (file.type?.toLowerCase() === 'image/heic') {
      await loadHeic2Any();
      file = await heic2any({
        blob: file,
        toType: 'image/jpeg',
        quality: 0.8,
      });
    }

    if (file) {
      file = await imageCompression(
        file,
        isMobile() ? mobileOptions : desktopOptions
      );
      const reader = new FileReader();
      reader.onloadend = () => {
        x(reader.result);
      };
      reader.readAsDataURL(file);
    }
  });
};

export const validateDataOnReGenerateImage = (userInfo) => {
  if (
    !sessionStorage.getItem('originalIdStorage') ||
    !sessionStorage.getItem('selectedModeStorage') ||
    sessionStorage.getItem('ageStorage') === null ||
    (sessionStorage.getItem('ageStorage') !== '0' &&
      !userInfo.plan.ageAccess) ||
    !sessionStorage.getItem('bodyTypeStorage') ||
    (sessionStorage.getItem('bodyTypeStorage') !== 'AUTO' &&
      !userInfo.plan.bodyTypeAccess) ||
    !sessionStorage.getItem('resolutionStorage') ||
    (sessionStorage.getItem('resolutionStorage') === 'HD' &&
      !userInfo.plan.qualites.includes(1)) ||
    (sessionStorage.getItem('resolutionStorage') === 'UHD' &&
      !userInfo.plan.qualites.includes(2))
  )
    return false;

  return true;
};

export const getGoogleClientId = async () => {
  if (window.gtag) {
    console.log('gtag exists');
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        clearTimeout(timer);
        console.error('Timeout: gtag request took too long');
        resolve();
      }, 3000);

      if (window.gtag) {
        window.gtag('get', 'G-24QHDY65WY', 'client_id', (client_id) => {
          clearTimeout(timer);
          if (client_id) {
            console.log('client_id', client_id);
            resolve(client_id);
          } else {
            console.error('No client ID found');
            resolve();
          }
        });
      }
    });
  }
};

export const getUtm = () => {
  return {
    utm_type: sbjs?.get?.first?.typ,
    utm_source: sbjs?.get?.first?.src,
    utm_medium: sbjs?.get?.current?.mdm,
    utm_campaign: sbjs?.get?.current?.cmp,
    utm_keyword: sbjs?.get?.current?.trm,
    utm_content: sbjs?.get?.current?.cnt,
    utm_page_ref: sbjs?.get?.current_add?.rf,
  };
};

export const capitalizeFirstLetter = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
