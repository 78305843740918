import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AddressInfoSliceState {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  address: string;
  country: string;
  city: string;
  state: string;
  region: string;
  zone: string;
  zip: string;
}

const initialState: AddressInfoSliceState = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  address: '',
  country: '',
  city: '',
  state: '',
  region: '',
  zone: '',
  zip: '',
};

export const addressInfo = createSlice({
  name: 'addressInfo',
  initialState,
  reducers: {
    setFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    setPhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
    setAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },
    setCountry: (state, action: PayloadAction<string>) => {
      state.country = action.payload;
    },
    setCity: (state, action: PayloadAction<string>) => {
      state.city = action.payload;
    },
    setState: (state, action: PayloadAction<string>) => {
      state.state = action.payload;
    },
    setRegion: (state, action: PayloadAction<string>) => {
      state.region = action.payload;
    },
    setZone: (state, action: PayloadAction<string>) => {
      state.zone = action.payload;
    },
    setZip: (state, action: PayloadAction<string>) => {
      state.zip = action.payload;
    },
  },
});

export const {
  setFirstName,
  setLastName,
  setPhoneNumber,
  setAddress,
  setCountry,
  setCity,
  setState,
  setRegion,
  setZone,
  setZip,
} = addressInfo.actions;
export default addressInfo.reducer;
