export const LANGUAGES = [
  {
    codeName: 'en',
    fullName: 'English',
  },
  {
    codeName: 'es',
    fullName: 'Español',
  },
  {
    codeName: 'jp',
    fullName: '日本語',
  },
  {
    codeName: 'fr',
    fullName: 'Français',
  },
  {
    codeName: 'de',
    fullName: 'Deutsch',
  },
  {
    codeName: 'ru',
    fullName: 'Русский',
  },

  {
    codeName: 'it',
    fullName: 'Italiano',
  },

  {
    codeName: 'kr',
    fullName: '한국어',
  },

  {
    codeName: 'pl',
    fullName: 'Polski',
  },
  {
    codeName: 'cn',
    fullName: '中文',
  },

  {
    codeName: 'pt',
    fullName: 'Português',
  },

  {
    codeName: 'hi',
    fullName: 'Hindi',
  },
];
