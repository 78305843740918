import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { validateEmail, validatePassword } from '../../../utils/utils.js';

interface ILoginPopupProps {
  closeModal: () => void;
  handleLogin: (email: string, password: string) => void;
  email: string;
  password: string;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  isPasswordWrong: boolean;
  isUserRegistered: string;
  isEmailConfirmed: boolean;
  setShowRecoveryModal: (show: boolean) => void;
}
const LoginPopup: React.FC<ILoginPopupProps> = ({
  closeModal,
  handleLogin,
  email,
  password,
  setEmail,
  setPassword,
  isPasswordWrong,
  isUserRegistered,
  isEmailConfirmed,
  setShowRecoveryModal,
}) => {
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const { t } = useTranslation();
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLoginClick = () => {
    const emailValid = validateEmail(email);
    const passwordValid = validatePassword(password);

    setIsEmailValid(emailValid);
    setIsPasswordValid(passwordValid);

    if (emailValid && passwordValid) {
      handleLogin(email, password);
    }
  };

  return (
    <form
      className="w-full flex items-center justify-between flex-col h-full lg:h-auto gap-[20px]"
      onSubmit={(event) => {
        event.preventDefault();
        handleLoginClick();
      }}
    >
      <div className="flex items-center w-full flex-col gap-[16px]">
        <div className="flex items-center w-full flex-col gap-[28px]">
          <div className="w-full relative">
            <input
              type="email"
              className={`px-[24px] w-full p2-desktop h-[64px] rounded-full bg-[#121212] text-[16px] placeholder-[#FFFFFF40] placeholder-[14px] ${
                isEmailValid ? '' : 'border-[1px] pl-[23px] border-[#FF3535]'
              }`}
              placeholder="Enter your e-mail"
              value={email}
              onChange={handleEmailChange}
              style={{ fontWeight: 600 }}
            />
            {!isEmailValid && (
              <p className="pl-[24px] text-[#FF3535] absolute top-[68px] text-[12px] sm:text-[12px]">
                {t('please_enter_email')}
              </p>
            )}
          </div>

          <div className="w-full relative">
            <input
              type="password"
              className={`px-[24px] w-full p2-desktop h-[64px] rounded-full bg-[#121212] text-[16px] placeholder-[#FFFFFF40] placeholder-[14px] ${
                isPasswordValid ? '' : 'border-[1px] pl-[23px] border-[#FF3535]'
              }`}
              placeholder={t('enter_password')}
              value={password}
              onChange={handlePasswordChange}
              style={{ fontWeight: 600 }}
            />

            {(!isPasswordValid && (
              <p className="pl-[24px] absolute top-[68px] text-[#FF3535] text-[10px] sm:text-[12px]">
                {t('login_errors.error1')}
              </p>
            )) ||
              (isPasswordWrong && (
                <p className="pl-[24px] absolute top-[68px] text-[#FF3535] text-[10px] sm:text-[12px]">
                  {t('login_errors.error2')}
                </p>
              )) ||
              (isUserRegistered === 'false' && (
                <p className="pl-[24px] absolute top-[68px] text-[#FF3535] text-[10px] sm:text-[12px]">
                  {t('login_errors.error3')}
                </p>
              )) ||
              (!isEmailConfirmed && (
                <p className="pl-[24px] absolute top-[68px] text-[#FF3535] text-[10px] sm:text-[12px]">
                  {t('login_errors.error4')}
                </p>
              ))}
          </div>

          <button
            type="button"
            className="text-[14px] text-[#808080] hover:text-[#FFFFFF] active:scale-[97%] mt-[55px] lg:mt-0"
            onClick={(event) => {
              event.preventDefault();
              closeModal();
              setShowRecoveryModal(true);
            }}
          >
            {t('forgot_password')}
          </button>
        </div>

        <input
          className="mt-12 h-[64px] flex text-center p2-desktop justify-center items-center w-full rounded-[42px] bg-orange transition-all lg:hover:bg-[#ff9330] active:scale-[97%] active:bg-[#b75800] cursor-pointer"
          type="submit"
          value={'Login'}
        />
      </div>

      <button
        className="h-[64px] min-h-[64px] login-popup-back flex text-center p2-desktop justify-center items-center w-full rounded-[32px] hover:bg-[#232323] active:text-white/25"
        onClick={closeModal}
      >
        {t('back')}
      </button>
    </form>
  );
};

export default LoginPopup;
