import { createSlice } from '@reduxjs/toolkit';

import { UserPlan } from '../traits/UserPlan';
import { Plan } from '../types/user';

const initialState = {
  authMethod: '',
  email: '',
  endFreeGenerations: false,
  experimentId: null,
  freeGenerationsSet: false,
  generationsLeft: 0,
  lastDateSendCode: null,
  metricUserCode: '',
  plan: Plan.NO,
  regSource: '',
  registeredDate: null,
  refsInvited: 0,
  inviteCode: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserInfo: (state, action) => {
      const payload = action.payload;
      for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
     //   if (Object.prototype.hasOwnProperty.call(payload, key)) {
          state[key] = payload[key];
        }
      }
    },
    // clearUserInfo: () => {
    //   initialState;
    // },
    // updateUserInfo: (state, action) => {
    //   const payload = action.payload;
    //   return {
    //     ...state,
    //     ...payload,
    //   };
    // },
    clearUserInfo: () => initialState
  },
});

export const { updateUserInfo, clearUserInfo } = userSlice.actions;

export default userSlice.reducer;
