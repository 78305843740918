import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';
import React from 'react';
import ReactDOM from 'react-dom/client';
import sbjs from 'sourcebuster';

import App from './App.jsx';

Sentry.init({
  dsn: 'https://05275d2e5918ad991848c52e63c18d5f@o4506819134160896.ingest.sentry.io/4506825345400832',
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://nudify.online'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

sbjs.init();

// const redirectUrls = ['https://photowizard.app', 'https://photowizard.world'];

// const getRandomInt = (max) => {
//   return Math.floor(Math.random() * Math.floor(max));
// };

// const getRandomDomain = () => {
//   return redirectUrls[getRandomInt(redirectUrls.length)];
// };

// const performRedirect = (domain) => {
//   // Редиректы происходят ТОЛЬКО когда пользователь заходит на этот домен. На других доменах редиректы не происходят.
//   if (domain !== 'https://photowizard.app') {
//     return;
//   }

//   const savedDomain = Cookies.get('redirectDomain');
//   console.log('savedDomain', savedDomain);
//   // for localhost working
//   // const savedDomain = 'http://localhost:3000';
//   if (savedDomain && savedDomain !== domain) {
//     window.location.href = savedDomain;
//   } else if (savedDomain && savedDomain === domain) {
//     return;
//   } else {
//     const newDomain = getRandomDomain();
//     if (newDomain !== domain) {
//       Cookies.set('redirectDomain', newDomain);
//       window.location.href = newDomain;
//     } else {
//       return;
//     }
//   }
// };

// const currentDomain = window.location.origin;
// performRedirect(currentDomain);

export const API_HOST =
  location?.host?.indexOf('undressit.dev') !== -1 ||
  location?.host?.indexOf('localhost:3000') !== -1
    ? 'https://api.undressit.dev'
    : 'https://api.nudify.online';
export const SITE_NAME = location?.host;

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
