export enum BodyType {
  AUTO = 'auto',
  SKINNY = 'skinny',
  SLIM = 'slim',
  HOURGLASS = 'hourglass',
  // CURVY = 'curvy',
  ATHLETIC = 'athletic',
  CHUBBY = 'chubby',
}

export enum Prompts {
  SEX = 'SEX',
  FOREST = 'FOREST',
  UNDRESS = 'UNDRESS',
  CUSTOM = 'CUSTOM',
  BARBIE = 'BARBIE',
  ANIME = 'ANIME',
  TUXEDO = 'TUXEDO',
  ANY_DRESS = 'ANY_DRESS',
  BLACK_DRESS = 'BLACK_DRESS',
  BIKINI = 'BIKINI',
  LINGERIE = 'LINGERIE',
  STOCKINGS = 'STOCKINGS',
  LATEX = 'LATEX',
  NIGHTIE = 'NIGHTIE',
  TRUMP = 'TRUMP',
}
