import { useGoogleLogin } from '@react-oauth/google';
import Cookies from 'js-cookie';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GTM_EVENTS,
  REGISTRATION_TYPES,
  AUTHORIZATION,
} from '../../constants/text';
import { GTMContext } from '../../context/GTMContext';
import { sendLoginRequestV2 } from '../../packets/auth/LoginRequest';
import { metricChooseAuth } from '../../packets/user/UserInfoRequestPacket';

export function GoogleLoginButton({
  onSuccess,
  onFail,
  captchaToken,
  newVariant = false,
}) {
  const { dataLayerPush } = useContext(GTMContext);
  const { t } = useTranslation();
  const handleSuccess = async (tokenResponse) => {
    const token = tokenResponse.access_token;
    let loginResponse;
    try {
      loginResponse = await sendLoginRequestV2(
        token,
        'GOOGLE',
        captchaToken || 'null'
      );
    } catch (e) {
      alert(e.toString());
      onFail(e?.response?.data);
      return;
    }

    if (loginResponse.data !== null) {
      Cookies.set('token', loginResponse.data.token);
    }
    if (onSuccess) {
      onSuccess();
    }
    dataLayerPush({
      event: `${
        loginResponse.data.isRegistration
          ? GTM_EVENTS.USER_REGISTERED
          : GTM_EVENTS.USER_AUTHORIZED
      }`,
      event_cat: AUTHORIZATION,
      event_name: 'go',
      registration_type: REGISTRATION_TYPES.GOOGLE,
      param_success: 'yes',
    });
  };

  const login = useGoogleLogin({
    scope: 'email',
    onSuccess: handleSuccess,
  });

  const handleClick = () => {
    metricChooseAuth('google');
    login();
  };
  const [isHovered, setIsHovered] = useState(false);

  if (newVariant) {
    return (
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleClick}
        className="flex items-center justify-center p-[6px] rounded-[14px] h-[56px] w-full bg-white cursor-pointer hover:bg-[#E8E7F1]"
      >
        <img
          className="h-[20px] w-[26px]"
          src="/icons/gglLogo.svg"
          alt="google logo"
        />
      </div>
    );
  }
  return (
    <>
      <button
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="mmd:hidden flex justify-center items-center h-[48px] w-full bg-[#FFFFFF] rounded-[24px] p-[6px] self-center"
        onClick={handleClick}
      >
        <img
          className="h-[24px] w-[24px]"
          src="/icons/gglLogo.svg"
          alt="google logo"
        />
      </button>
      <button
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="mmd:flex hidden h-[88px] w-full items-center justify-between gap-[16px] self-stretch rounded-[44px] bg-[#121212] pl-[16px] pr-[24px] text-start transition-all hover:bg-[#FFFFFF]"
        onClick={handleClick}
      >
        <div className="flex items-center justify-center gap-[16px]">
          <div className="flex h-[48px] w-[48px] items-center justify-center rounded-full bg-[#FFFFFF]">
            <img
              className="h-[20px] w-[26px]"
              src="/icons/gglLogo.svg"
              alt="google logo"
            />
          </div>
          <div className="hidden flex flex-col mmd:flex">
            <p
              className={`text-[12px] ${
                isHovered ? 'text-[#20202080]' : 'text-[#FFFFFF80]'
              }`}
            >
              {t('login_with')}
            </p>
            <p
              className={`text-[16px] font-semibold ${
                isHovered ? 'text-[#000000]' : ''
              }`}
            >
              Google
            </p>
          </div>
        </div>

        <img
          src="/icons/keyIcon.svg"
          alt="key icon"
          className="hidden mmd:block h-[24px] w-[24px]"
        />
      </button>
    </>
  );
}
