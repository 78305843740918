import React, { useCallback, useRef, useState } from 'react';
import './index.css';
import classnames from 'classnames';
import { Cross } from 'components/common/Accordion/cross';

export interface AccordionProps {
  title: string;
  children: React.ReactNode;
  className?: string;
}
const Accordion: React.FC<AccordionProps> = ({
  children,
  title,
  className,
}) => {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const [hover, setHover] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClick = useCallback(() => {
    setOpen(!open);
    if (!open) {
      setHeight(ref?.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [open]);

  const handleMouseOver = useCallback(() => {
    setHover(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  return (
    <div
      className={classnames(
        'accordion flex-col',
        { accordion_open: open },
        className
      )}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <div className="flex items-center justify-between">
        <h3
          className={classnames('accordion__title', {
            accordion__title_orange: hover,
          })}
        >
          {title}
        </h3>
        <div
          className={classnames(
            'h-[42px] min-w-[42px] ml-[8px] flex items-center justify-center accordion__title__button',
            { accordion__title__button_orange: hover }
          )}
        >
          <Cross
            className={classnames('h-[30px] w-[30px] transition-all', {
              'rotate-45': open,
            })}
            fill={hover && 'white'}
          />
        </div>
      </div>
      <div
        ref={ref}
        className="accordion__content pr-[42px]"
        style={{ maxHeight: height }}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
