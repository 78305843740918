import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  paypalSelected: false,
  fiatByCardSelected: false,
  epochSelected: false,
  redeemSelected: false,
  cryptoByCardSelected: false,
  cryptoSelected: false,
  cryptoManualSelected: false,
  plan:{
    selectedPlan: undefined, 
    months: null, //period
    longTermPrices: {}, //longTerm
    prices: {},
    redeemActivationStatus: 'CLOSED' //onGetBonus, onGetBonus={() => setRedeemActivationStatus('WAIT_CODE')}
  }
};

  
export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    updatePaymentSelected: (state, action) => {
      const payload = action.payload;
      for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
          state[key] = payload[key];
        }
      }
    },
    clearPaymentSelected: () => {
      initialState;
    },
    setSelectedPlan: (state, action) => {
      state.plan.selectedPlan = action.payload;
    },
    setMonths: (state, action) => {
      state.plan.months = action.payload;
    },
    setLongTermPrices: (state, action) => {
      state.plan.longTermPrices = action.payload;
    },
    setPrices: (state, action) => {
      state.plan.prices = action.payload;
    },
    setRedeemActivationStatus: (state, action) => {
      state.plan.redeemActivationStatus = action.payload;
    },
  },
});

export const { 
  updatePaymentSelected, 
  clearPaymentSelected, 
  setSelectedPlan, 
  setMonths, 
  setLongTermPrices, 
  setPrices, 
  setRedeemActivationStatus 
} = paymentSlice.actions;

export default paymentSlice.reducer;
