import React, { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';

import useDomain from '../hooks/useDomain';

export const GTMContext = createContext<GTMContextType | null>(null);

export const useGTM = () => useContext(GTMContext);

declare global {
  interface Window {
    dataLayer: any[];
  }
}

interface GTMContextType {
  dataLayerPush: (eventData: any) => void;
}

export const GTMProvider = ({ children }) => {
  const userData = useSelector((state: any) => state.user);
  const { projectName } = useDomain();
  const dataLayerPush = (eventData) => {
    const baseData = {
      project: projectName,
      user_id: userData.email,
    };
    const data = { ...baseData, ...eventData };

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  };

  return (
    <GTMContext.Provider value={{ dataLayerPush }}>
      {children}
    </GTMContext.Provider>
  );
};
