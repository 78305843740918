import React from 'react';

interface ICardProps {
  title: string;
  children: string;
  svg: React.ReactNode;
}

const Card: React.FC<ICardProps> = ({ title, children, svg }) => {
  return (
    <div className="flex flex-col rounded-3xl items-center relative w-full h-full bg-black">
      <div className="z-0 self-start">{svg}</div>
      <div className="absolute z-10 text-center py-5 items-center flex flex-col">
        <h3 className="text-xl">{title}</h3>
        <p className="font-normal text-[#9D9BA6] text-sm w-[90%] ">
          {children}
        </p>
      </div>
    </div>
  );
};
export default Card;
