import ButtonV2 from 'components/common/ButtonV2/index.jsx';
import { ITimeUntilMidnight } from 'components/edit/components/percentageBanner';
import { motion, Variants } from 'framer-motion';
import Cookies from 'js-cookie';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  bannerMetric,
  getUserCode,
} from '../../packets/user/UserInfoRequestPacket.js';
import { openInNewTab, getUtm } from '../../utils/utils.js';

let banner = parseInt(Cookies.get('anima_banner'));
if (!banner) {
  banner = Math.floor(Math.random() * 5) + 1;
  Cookies.set('anima_banner', banner.toString());
}

const expandByDefault = Cookies.get('dont_expand_nudx_banner') !== '1';

const AnimaBanner = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showAnimaBanner, setShowAnimaBanner] = useState(false);
  // const [time, setTime] = useState<ITimeUntilMidnight>(getTimeUntilMidnight());
  const bannerRef = useRef(null);

  const handleScroll = () => {
    if (window.scrollY > 10) {
      setIsExpanded(false);
    }
  };

  const handleClickOutside = (event) => {
    if (
      bannerRef.current &&
      !bannerRef.current.contains(event.target) &&
      !event.target.closest('.react-responsive-modal-root')
    ) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const pulseVariants: Variants = {
    initial: { opacity: 0.5, scale: 1 },
    animate: {
      opacity: [0.5, 0],
      scale: [1, 1.2],
      transition: {
        duration: 1,
        repeat: Infinity,
        repeatType: 'mirror',
        ease: 'easeInOut',
      },
    },
  };

  const expandVariants: Variants = {
    collapsed: {
      width: banner === 1 || banner === 2 ? 332 : '100%',
      height: banner === 1 || banner === 2 ? 82 : 60,
      transition: { duration: 0.5 },
    },
    expanded: {
      width: banner === 1 || banner === 2 ? 'auto' : '100%',
      height: 'auto',
      transition: { duration: 0.5 },
    },
  };

  const handleExpandClick = () => {
    const newState = !isExpanded;
    if (banner !== 5) {
      if (newState && !Cookies.get('sent_expand_event')) {
        Cookies.set('sent_expand_event', '1');
        const utms = getUtm();
        void bannerMetric(
          Object.assign(utms, {
            banner_type: `open-main-banner-type-${banner === 1 ? '2' : banner === 2 ? '4' : banner === 3 ? '6' : banner === 4 ? '7' : '8'}`,
          })
        );
      }

      setIsExpanded(newState);
    }
  };

  const handleOpenLink = (banner_type: string, e: React.MouseEvent) => {
    e.stopPropagation();

    const old_dont_expand_nudx_banner = Cookies.get('dont_expand_nudx_banner');
    Cookies.set('dont_expand_nudx_banner', '1');

    const utms = getUtm();
    const ucode = getUserCode();

    openInNewTab(
      `https://nudx.ai/?utm_type=${utms.utm_type}&utm_source=${utms.utm_source}&utm_medium=${utms.utm_medium}&utm_campaign=${utms.utm_campaign}&utm_keyword=${utms.utm_keyword}&utm_content=${utms.utm_content}&utm_page_ref=${utms.utm_page_ref}&ucode=${ucode}&b=${banner_type}`
    );

    // send only once
    if (!old_dont_expand_nudx_banner) {
      void bannerMetric(Object.assign(utms, { banner_type }));
    }
  };

  // function getTimeUntilMidnight() {
  //   const now = new Date();
  //   const then = new Date(now);
  //   then.setHours(24, 0, 0, 0);

  //   const diff = then.valueOf() - now.valueOf();

  //   const hours = Math.floor(diff / 3.6e6);
  //   const minutes = Math.floor((diff % 3.6e6) / 6e4);
  //   const seconds = Math.floor((diff % 6e4) / 1000);

  //   return { hours, minutes, seconds };
  // }

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setTime(getTimeUntilMidnight());
  //   }, 1000);
  //   return () => clearInterval(timer);
  // }, []);

  const checkBanner = () => {
    switch (pathname) {
      case '/':
      case '/undress-ai':
        setShowAnimaBanner(false);
        break;
      default:
        setShowAnimaBanner(true);
        break;
    }
  };

  useEffect(() => {
    checkBanner();
  }, [pathname]);

  if (!showAnimaBanner) {
    return null;
  }

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: banner === 1 || banner === 2 ? 0 : 60,
        backgroundColor: 'black',
      }}
    >
      <motion.div
        ref={bannerRef}
        initial="collapsed"
        animate={isExpanded ? 'expanded' : 'collapsed'}
        variants={expandVariants}
        onClick={handleExpandClick}
        style={{
          position: 'fixed',
          top: banner === 1 || banner === 2 ? 24 : 0,
          right: banner === 1 || banner === 2 ? 14 : 0,
          backgroundColor: `${
            banner === 2 || banner === 5 || banner === 4 ? '#5C3DE7' : '#EF5736'
          }`,
          borderRadius: banner === 1 || banner === 2 ? 24 : 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          zIndex: 10000,
        }}
        className="mmd:ml-0 ml-[14px] w-full mb-[60px] mmd:mb-[0px]"
      >
        {isExpanded ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            style={{ width: '100%', height: '100%' }}
          >
            {banner === 1 ? (
              <div
                className="main-banner-type-2"
                onClick={handleOpenLink.bind(this, 'main-banner-type-2')}
                style={{ width: '100%', height: '100%' }}
              >
                <div className="ml-2 mt-4 flex flex-row items-center justify-between">
                  <img
                    className="mmd:w-[117px] mmd:h-[61px] w-[120px] h-[73px]"
                    src="/icons/nudxLogo.svg"
                    alt="nudx logo"
                  />
                  <div className="mr-4 h-8 px-3.5 py-2.5 bg-red-600 rounded-[14px] justify-start items-center gap-2.5 inline-flex">
                    <div className="grow shrink basis-0 text-white text-xs mmd:text-base font-bold leading-snug">
                      {t('banners.win_100')}
                    </div>
                  </div>
                </div>
                <div className="flex mmd:flex-row flex-col m-4 gap-2">
                  <div className="mmd:w-[186px] w-full mmd:h-[172px] h-auto p-6 bg-white rounded-[14px] flex-col justify-center items-start gap-2 inline-flex">
                    <div className="self-stretch text-stone-950 text-base font-bold leading-snug">
                      {t('banners.earn_10x')}
                    </div>
                    <div className="self-stretch text-zinc-700 text-[13px] font-normal leading-[18px]">
                      {t('banners.all_profit')}
                    </div>
                  </div>

                  <div className="mmd:w-[186px] mmd:h-[172px] w-full h-auto p-6 bg-white rounded-[14px] flex-col justify-center items-start gap-2 inline-flex">
                    <div className="self-stretch text-stone-950 text-base font-bold leading-snug">
                      {t('banners.insider_analytics')}
                    </div>
                    <div className="self-stretch text-zinc-700 text-[13px] font-normal leading-[18px]">
                      {t('banners.get_personal_access')}
                    </div>
                  </div>

                  <div className="mmd:w-[186px] mmd:h-[172px] w-full h-auto p-6 bg-white rounded-[14px] flex-col justify-center items-start gap-2 inline-flex">
                    <div className="self-stretch text-stone-950 text-base font-bold leading-snug">
                      {t('banners.only_for_you')}
                    </div>
                    <div className="self-stretch text-zinc-700 text-[13px] font-normal leading-[18px]">
                      {t('banners.token_presale')}
                    </div>
                  </div>
                </div>
              </div>
            ) : banner === 2 ? (
              <div
                className="main-banner-type-4"
                onClick={handleOpenLink.bind(this, 'main-banner-type-4')}
                style={{ width: '100%', height: '100%' }}
              >
                <div className="ml-2 mt-[11px] flex flex-row items-center justify-between">
                  <img
                    className="mmd:w-[117px] mmd:h-[61px] w-[120px] h-[73px]"
                    src="/icons/nudxLogo.svg"
                    alt="nudx logo"
                  />
                  <div className="mr-4 h-8 px-3.5 py-2.5 bg-[#7B66FF] rounded-[14px] justify-start items-center gap-2.5 inline-flex w-[70%]">
                    <div className="grow shrink basis-0 text-white text-xs mmd:text-[12px] font-bold leading-snug">
                      {t('banners.win_100')}
                    </div>
                  </div>
                </div>
                <div className="flex mmd:flex-row flex-col m-4 mt-1 mb-[8px] mmd:mb-[14px] gap-2">
                  <div className="mmd:w-[186px] w-full mmd:h-[172px] h-auto p-6 bg-[#4227BD] rounded-[14px] flex-col justify-center items-start gap-2 inline-flex">
                    <div className="self-stretch text-white text-base font-bold leading-snug">
                      {t('banners.earn_10x')}
                    </div>
                    <div className="self-stretch textwhite text-[13px] font-normal leading-[18px]">
                      {t('banners.all_profit')}
                    </div>
                  </div>

                  <div className="mmd:w-[186px] mmd:h-[172px] w-full h-auto p-6 bg-[#4227BD] rounded-[14px] flex-col justify-center items-start gap-2 inline-flex">
                    <div className="self-stretch text-white text-base font-bold leading-snug">
                      {t('banners.insider_analytics')}
                    </div>
                    <div className="self-stretch textwhite text-[13px] font-normal leading-[18px]">
                      {t('banners.get_personal_access')}
                    </div>
                  </div>

                  <div className="mmd:w-[186px] mmd:h-[172px] w-full h-auto p-6 bg-[#4227BD] rounded-[14px] flex-col justify-center items-start gap-2 inline-flex">
                    <div className="self-stretch text-white text-base font-bold leading-snug">
                      {t('banners.only_for_you')}
                    </div>
                    <div className="self-stretch textwhite text-[13px] font-normal leading-[18px]">
                      {t('banners.token_presale')}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col mmd:flex-row gap-[14px] mmd:gap-[34px] items-center justify-center mb-[12px]">
                  <div className="flex gap-[6px] items-center">
                    <p className="mmd:block hidden">
                      {t('banners.discover_nudx')}
                    </p>
                    <p className="mmd:hidden block text-center">
                      {t('banners.discover_nudx')}
                    </p>

                    <img
                      src="/icons/ArrowToRight.svg"
                      alt="arrow"
                      className="w-[26px] h-[8px] hidden mmd:block"
                    />
                  </div>
                  <ButtonV2 className="w-[165px] h-[45px] py-[12px] px-[44px] text-[13px] font-medium leading-[21px] font-montserrat tracking-[0.52px] bg-gradient-to-b from-[#EF5837_1%] to-[#D93634_100%] hover:from-[#EF5837_100%] rounded-[8px]">
                    {t('discover').toUpperCase()}
                  </ButtonV2>
                </div>
              </div>
            ) : banner === 3 ? (
              <div
                className="main-banner-type-6"
                onClick={handleOpenLink.bind(this, 'main-banner-type-6')}
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div className="mmd:w-[50%]">
                  <div className="ml-2 mt-4 flex flex-row items-center justify-between">
                    <img
                      className="mmd:w-[117px] mmd:h-[61px] w-[120px] h-[73px]"
                      src="/icons/nudxLogo.svg"
                      alt="nudx logo"
                    />
                    <div className="mr-4 h-8 px-3.5 py-2.5 bg-red-600 rounded-[14px] justify-start items-center gap-2.5 inline-flex">
                      <div className="grow shrink basis-0 text-white text-xs mmd:text-base font-bold leading-snug transition ease-in-out delay-150">
                        {t('banners.win_100')}
                        {/* Click to discover $NudX presale and win $100 */}
                      </div>
                    </div>
                  </div>
                  <div className="flex mmd:flex-row flex-col m-4 gap-2 justify-between">
                    <div className="mmd:w-full w-full h-auto p-6 bg-white rounded-[14px] flex-col justify-center items-start gap-2 inline-flex">
                      <div className="self-stretch text-stone-950 text-base font-bold leading-snug">
                        {t('banners.earn_10x')}
                        {/* Earn x3 to x10 */}
                      </div>
                      <div className="self-stretch text-zinc-700 text-[13px] font-normal leading-[18px]">
                        {t('banners.all_profit')}
                        {/* Be the first to buy on $NudX Presale to gain ALL PROFIT */}
                      </div>
                    </div>

                    <div className="mmd:w-full w-full h-auto p-6 bg-white rounded-[14px] flex-col justify-center items-start gap-2 inline-flex">
                      <div className="self-stretch text-stone-950 text-base font-bold leading-snug">
                        {t('banners.insider_analytics')}
                        {/* Insider analytics */}
                      </div>
                      <div className="self-stretch text-zinc-700 text-[13px] font-normal leading-[18px]">
                        {t('banners.get_personal_access')}
                        {/* Get personal access to NudX analytics before it goes */}
                        {/* live */}
                      </div>
                    </div>

                    <div className="mmd:w-full w-full h-auto p-6 bg-white rounded-[14px] flex-col justify-center items-start gap-2 inline-flex">
                      <div className="self-stretch text-stone-950 text-base font-bold leading-snug">
                        {t('banners.only_for_you')}
                        {/* Only for YOU */}
                      </div>
                      <div className="self-stretch text-zinc-700 text-[13px] font-normal leading-[18px]">
                        {t('banners.token_presale')}
                        {/* $NudX token presale is available only for Nudify users. */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="main-banner-type-7"
                onClick={handleOpenLink.bind(this, 'main-banner-type-7')}
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div className="mmd:w-[50%]">
                  <div className="ml-2 mt-[11px] flex flex-row items-center justify-between">
                    <img
                      className="mmd:w-[117px] mmd:h-[61px] w-[120px] h-[73px]"
                      src="/icons/nudxLogo.svg"
                      alt="nudx logo"
                    />
                    <div className="mr-4 h-8 px-2.5 py-2.5 bg-[#7B66FF] rounded-[14px] justify-start items-center gap-2.5 inline-flex w-[70%]">
                      <div className="grow shrink basis-0 text-white text-xs mmd:text-sm font-bold leading-snug">
                        {t('banners.win_100')}
                        {/* Click to discover $NudX presale and win $100 &gt;&gt; */}
                      </div>
                    </div>
                  </div>
                  <div className="flex mmd:flex-row flex-col m-4 mt-1 mb-[8px] mmd:mb-[14px] gap-2 justify-between">
                    <div className="w-full h-auto p-6 bg-[#4227BD] rounded-[14px] flex-col justify-center items-start gap-2 inline-flex">
                      <div className="self-stretch text-white text-base font-bold leading-snug">
                        {t('banners.earn_10x')}
                        {/* Earn x3 to x10 */}
                      </div>
                      <div className="self-stretch textwhite text-[13px] font-normal leading-[18px]">
                        {t('banners.all_profit')}
                        {/* Be the first to buy on $NudX Presale to gain ALL PROFIT */}
                      </div>
                    </div>

                    <div className="w-full h-auto p-6 bg-[#4227BD] rounded-[14px] flex-col justify-center items-start gap-2 inline-flex">
                      <div className="self-stretch text-white text-base font-bold leading-snug">
                        {t('banners.insider_analytics')}
                        {/* Insider analytics */}
                      </div>
                      <div className="self-stretch textwhite text-[13px] font-normal leading-[18px]">
                        {t('banners.get_personal_access')}
                        {/* Get personal access to NudX analytics before it goes
                        live */}
                      </div>
                    </div>

                    <div className="w-full h-auto p-6 bg-[#4227BD] rounded-[14px] flex-col justify-center items-start gap-2 inline-flex">
                      <div className="self-stretch text-white text-base font-bold leading-snug">
                        {t('banners.only_for_you')}
                        {/* Only for YOU */}
                      </div>
                      <div className="self-stretch textwhite text-[13px] font-normal leading-[18px]">
                        {t('banners.token_presale')}
                        {/* $NudX token presale is available only for Nudify users. */}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col mmd:flex-row gap-[14px] mmd:gap-[34px] items-center justify-center mb-[12px]">
                    <div className="flex gap-[6px] items-center">
                      <p className="mmd:block hidden text-[16px]">
                        {t('banners.discover_nudx')}
                        {/* Discover $NudX presale and win $100 */}
                      </p>
                      <p className="mmd:hidden block text-center">
                        {t('banners.discover_nudx')}
                        {/* Discover $NudX <br />
                        presale and win $100 */}
                      </p>

                      <img
                        src="/icons/ArrowToRight.svg"
                        alt="arrow"
                        className="w-[26px] h-[8px] hidden mmd:block"
                      />
                    </div>
                    <ButtonV2 className="w-[165px] h-[45px] py-[12px] px-[44px] text-[13px] font-medium leading-[21px] font-montserrat tracking-[0.52px] bg-gradient-to-b from-[#EF5837_1%] to-[#D93634_100%] hover:from-[#EF5837_100%] rounded-[8px]">
                      {t('discover').toUpperCase()}
                      {/* DISCOVER */}
                    </ButtonV2>
                  </div>
                </div>
              </div>
            )}
          </motion.div>
        ) : (
          <div
            className={`flex ${
              banner === 5
                ? 'gap-[26px] w-full justify-around'
                : 'gap-[6px] pt-[5px] pb-[4px] pr-[14px]'
            }`}
          >
            <img
              src="/icons/nudxLogo.svg"
              alt="nudx logo"
              className={`${
                banner === 5 ? 'w-[117px] h-[61px] hidden' : 'h-[61px]'
              } mt-[8px]`}
            />
            {banner === 1 || banner === 2 ? (
              <div
                className={`px-[10px] py-[6px] flex items-center ${banner === 1 ? 'bg-[#EF5736]' : 'bg-[#7B66FF]'}  h-[34px] place-self-center rounded-[14px]`}
              >
                <div className="font-montserrat text-white text-[12px] font-medium leading-[15px] not-italic">
                  {t('banners.win_100')} &gt;&gt;
                </div>
              </div>
            ) : banner === 5 ? (
              <div
                className="main-banner-type-8"
                onClick={handleOpenLink.bind(this, 'main-banner-type-8')}
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
              >
                <div className="mmd:w-[60%]">
                  <div className="mmd:flex hidden items-center justify-around w-full">
                    <img
                      src="/icons/nudxLogo.svg"
                      alt="nudx logo"
                      className={`${banner === 5 ? 'w-[80px] ' : ''}`}
                    />
                    <div className="pr-[10px] flex items-center place-self-center text-center rounded-[14px]">
                      <div className="font-montserrat text-white text-[14px] font-medium leading-[20px] not-italic">
                        <motion.div
                          initial={{ scale: 1 }}
                          animate={{
                            scale: [1, 1.2, 1],
                          }}
                          transition={{
                            duration: 1.5,
                            delay: 0.2,
                            repeat: Infinity,
                            repeatType: 'mirror',
                            ease: 'easeInOut',
                          }}
                        >
                          {t('banners.win_100')}
                        </motion.div>
                        {/* Click to discover $NudX <br />
                      presale and win $100 */}
                      </div>
                    </div>
                    <ButtonV2 className="w-[137px] h-[42px] py-[10px] px-[32px] text-[13px] place-self-center font-medium leading-[21px] font-montserrat tracking-[0.52px] bg-gradient-to-b from-[#EF5837_1%] to-[#D93634_100%] hover:from-[#EF5837_100%] rounded-[8px]">
                      {t('discover').toUpperCase()}
                      {/* DISCOVER */}
                    </ButtonV2>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center mmd:hidden w-full">
                  <div className="flex justify-around w-full">
                    <img
                      src="/icons/nudxLogo.svg"
                      alt="nudx logo"
                      className={`${banner === 5 ? 'w-[80px]' : ''}`}
                    />
                    <div className="pr-[8px] pl-[8px] flex items-center place-self-center text-center rounded-[14px] mmd:hidden">
                      <div className="font-montserrat text-white text-[16px] font-medium leading-[20px] not-italic">
                        <motion.div
                          initial={{ scale: 1 }}
                          animate={{
                            scale: [1, 1.2, 1],
                          }}
                          transition={{
                            duration: 1.5,
                            delay: 0.2,
                            repeat: Infinity,
                            repeatType: 'mirror',
                            ease: 'easeInOut',
                          }}
                        >
                          {t('banners.tkn_presale')}
                        </motion.div>
                        {/* Token presale */}
                      </div>
                    </div>
                    <ButtonV2 className="w-[110px] h-[42px] py-[4px] px-[16px] text-[13px] place-self-center font-medium leading-[21px] font-montserrat tracking-[0.52px] bg-gradient-to-b from-[#EF5837_1%] to-[#D93634_100%] hover:from-[#EF5837_100%] rounded-[8px]">
                      {t('discover').toUpperCase()}
                      {/* DISCOVER */}
                    </ButtonV2>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={`px-[10px] py-[6px] flex items-center ${
                  banner === 4 ? 'bg-[#7B66FF]' : 'bg-[#EF5736]'
                }  h-[34px] place-self-center rounded-[14px]`}
              >
                <div className="font-montserrat text-white mmd:text-[18px] text-[16px] font-medium mmd:leading-[22px] leading-[20px] not-italic">
                  {t('banners.win_100')} &gt;&gt;
                  {/* Click to discover $NudX <br /> presale and win $100 &gt;&gt; */}
                </div>
              </div>
            )}
          </div>
        )}
      </motion.div>
      {!isExpanded && (
        <>
          <motion.div
            style={{
              position: 'fixed',
              top: banner === 1 || banner === 2 ? 24 : 0,
              right: banner === 1 || banner === 2 ? 14 : 0,
              width: banner === 1 || banner === 2 ? 332 : '120%',
              height: banner === 1 || banner === 2 ? 82 : 65,
              borderRadius: '24px',
              backgroundColor: `${
                banner === 2 || banner === 5 || banner === 4
                  ? '#5C3DE7'
                  : '#EF5736'
              }`,
              zIndex: 1,
            }}
            variants={pulseVariants}
            initial="initial"
            animate="animate"
          />
          <motion.div
            style={{
              position: 'fixed',
              top: banner === 1 || banner === 2 ? 24 : 0,
              right: banner === 1 || banner === 2 ? 14 : 0,
              width: banner === 1 || banner === 2 ? 332 : '120%',
              height: banner === 1 || banner === 2 ? 82 : 65,
              borderRadius: '24px',
              backgroundColor: `${
                banner === 2 || banner === 5 || banner === 4
                  ? '#5C3DE7'
                  : '#EF5736'
              }`,
              zIndex: 1,
            }}
            variants={pulseVariants}
            initial="initial"
            animate="animate"
          />
        </>
      )}
    </div>
  );
};

export default AnimaBanner;
