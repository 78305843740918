import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CanvasSliceState {
  originalImage: string;
  hideOriginalImage: boolean;
  maskImage: string | null;
  resultReady: boolean;
  resultImage: string | null;
  isDrawingAreaEmpty: boolean;
}

const initialState: CanvasSliceState = {
  originalImage: localStorage.getItem('originalImage') || '',
  hideOriginalImage: false,
  maskImage: null,
  resultReady: false,
  resultImage: null,
  isDrawingAreaEmpty: true,
};

export const canvasSlice = createSlice({
  name: 'canvas',
  initialState,
  reducers: {
    setCanvasState: (state, action: PayloadAction<CanvasSliceState>) => {
      Object.keys(action).forEach((key) => (state[key] = action[key]));
    },
    setOriginImage: (state, action: PayloadAction<string>) => {
      state.originalImage = action.payload;
    },
    setHideOriginalImage: (state, action: PayloadAction<boolean>) => {
      state.hideOriginalImage = action.payload;
    },
    setMaskImage: (state, action: PayloadAction<string | null>) => {
      state.maskImage = action.payload;
    },
    setResultReady: (state, action: PayloadAction<boolean>) => {
      state.resultReady = action.payload;
    },
    setResultImage: (state, action: PayloadAction<string | null>) => {
      state.resultImage = action.payload;
    },
    setIsDrawingAreaEmpty: (state, action: PayloadAction<boolean>) => {
      state.isDrawingAreaEmpty = action.payload;
    },
  },
});

export const {
  setCanvasState,
  setOriginImage,
  setHideOriginalImage,
  setMaskImage,
  setResultReady,
  setResultImage,
  setIsDrawingAreaEmpty,
} = canvasSlice.actions;
export default canvasSlice.reducer;
