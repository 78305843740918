import { useMemo } from 'react';

function useDomain() {
  const host = window.location?.host;

  const {
    backgroundClass,
    isButtonVisible,
    bannerClass,
    projectName,
    description,
    email,
  } = useMemo(() => {
    switch (host) {
      case 'nudify.online':
      case 'undressit.dev':
        return {
          projectName: 'nudify.online',
          backgroundClass: 'general-bg-nudify2',
          // backgroundClass: 'general-bg-nudifi',
          bannerClass: 'general-banner-nudifi',
          isButtonVisible: true,
          description: 'description_nude.part',
          email: 'contact@nudify.online',
        };
      default:
        return {
          projectName: 'magicphoto.app',
          backgroundClass: 'general-bg-nudify2',
          // backgroundClass: 'general-bg-nudifi',
          bannerClass: 'general-banner-nudifi',
          isButtonVisible: false,
          description: 'description.part',
          email: `contact@${host}`,
        };
    }
  }, [host]);

  return {
    backgroundClass,
    isButtonVisible,
    bannerClass,
    projectName,
    description,
    email,
  };
}

export default useDomain;
